import dayjs from 'dayjs';
import { isWithinInterval } from 'date-fns';
import { TagFormValues } from 'pages/CreateOrEditTag';
import QRCode from 'qrcode';
import {
  checkQCardRNAppDevice,
  postMessageToQCardApp,
} from './qcardReactNativeHandlers';
import { escapeAmpersandInUrl } from './string';

export const getTimeDifference = (timestamp: number): string => {
  // Convert timestamp to Date object
  const lastUpdated: Date = new Date(timestamp * 1000);

  // Calculate time difference
  const currentDateTime: Date = new Date();
  const timeDifference: number = currentDateTime.getTime() - lastUpdated.getTime();

  // Define time units in milliseconds
  const minute: number = 60 * 1000;
  const hour: number = 60 * minute;
  const day: number = 24 * hour;
  const week: number = 7 * day;
  const month: number = 30 * day; // Assuming each month has 30 days for simplicity
  const year: number = 365 * day; // Approximation for non-leap years

  // Calculate the difference in minutes, hours, days, weeks, months, and years
  const minutesDiff: number = Math.floor(timeDifference / minute);
  const hoursDiff: number = Math.floor(timeDifference / hour);
  const daysDiff: number = Math.floor(timeDifference / day);
  const weeksDiff: number = Math.floor(timeDifference / week);
  const monthsDiff: number = Math.floor(timeDifference / month);
  const yearsDiff: number = Math.floor(timeDifference / year);

  // Construct the string representation
  let output: string = '';

  if (timeDifference < minute) {
    output = 'a few seconds ago';
  } else if (minutesDiff < 60) {
    output = `${minutesDiff} ${minutesDiff === 1 ? 'minute' : 'minutes'} ago`;
  } else if (hoursDiff < 24) {
    output = `${hoursDiff} ${hoursDiff === 1 ? 'hour' : 'hours'} ago`;
  } else if (daysDiff < 7) {
    output = `${daysDiff} ${daysDiff === 1 ? 'day' : 'days'} ago`;
  } else if (weeksDiff < 4) {
    if (weeksDiff === 0) {
      output = '7 days ago';
    } else {
      output = `${weeksDiff} ${weeksDiff === 1 ? 'week' : 'weeks'} ago`;
    }
  } else if (monthsDiff >= 0 && monthsDiff < 12) {
    if (monthsDiff === 0) {
      output = '4 weeks ago';
    } else {
      output = `${monthsDiff} ${monthsDiff === 1 ? 'month' : 'months'} ago`;
    }
  } else {
    output = `${yearsDiff} ${yearsDiff === 1 ? 'year' : 'years'} ago`;
  }

  return output;
};

export const divideDayIntoIntervals = () => {
  const intervals = [];
  const intervalMinutes = 15;
  const totalMinutes = 24 * 60; // Total minutes in a day

  let currentMinutes = 0;

  while (currentMinutes < totalMinutes) {
    const hours = Math.floor(currentMinutes / 60);
    const minutes = currentMinutes % 60;

    const startTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
      2,
      '0'
    )}`;

    currentMinutes += intervalMinutes;

    const endHours = Math.floor(currentMinutes / 60);
    const endMinutes = currentMinutes % 60;

    const endTime = `${String(endHours).padStart(2, '0')}:${String(
      endMinutes
    ).padStart(2, '0')}`;

    intervals.push({ startTime, endTime });
  }

  return intervals;
};

export const findMatchingInterval = (isStartDate: boolean, timestamp: number) => {
  const timeIntervals = divideDayIntoIntervals();
  const itemArray = isStartDate
    ? timeIntervals.map((item) => ({ label: item.startTime, value: item.startTime }))
    : timeIntervals.map((item) => ({ label: item.endTime, value: item.endTime }));

  const dateTime = new Date(timestamp * 1000);
  const hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();

  // Round minutes to the nearest 15 minutes
  const roundedMinutes = Math.round(minutes / 15) * 15;

  // Format hours and minutes as a string
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = roundedMinutes.toString().padStart(2, '0');

  // Construct the time value
  const currentTime = `${formattedHours}:${formattedMinutes}`;

  // Find the corresponding interval from startMenuItemArray
  const matchingInterval = itemArray.find((item) => item.label === currentTime);

  return matchingInterval;
};

export const convertTimestamp = (dateString: any, timeString: any) => {
  const date = new Date(Date.parse(dateString));

  const [hours, minutes] = timeString.split(':');
  date.setHours(hours);
  date.setMinutes(minutes);

  return Math.floor(date.getTime()).toString();
};

export const convertTimestampToDateString = (timestamp?: string) => {
  if (timestamp) {
    const unixTimestamp = Number(timestamp);
    const dateTime = new Date(unixTimestamp * 1000);
    return dateTime.toString();
  } else {
    return dayjs().toString();
  }
};

export const downloadQRModal = async (qrCodeUrl: string) => {
  try {
    const qrCodeDataUrl = await QRCode.toDataURL(qrCodeUrl);
    if (checkQCardRNAppDevice()) {
      postMessageToQCardApp('downloadQrcode', {
        image: qrCodeDataUrl,
      });
    } else {
      const link = document.createElement('a');
      link.href = qrCodeDataUrl;
      link.download = 'qrcode.png';
      link.click();
    }
  } catch (error) {
    console.error('Error generating QR code:', error);
  }
};
interface tagItem {
  tag_name: string;
  tag_start_date?: string;
  tag_end_date?: string;
  target_id?: string;
}

export const formatTagParagraphFields = (
  values: TagFormValues,
  isDate: boolean,
  isEdit: boolean
) => {
  let paragraphBody: {
    field_campaign_events: tagItem[];
  } = {
    field_campaign_events: [],
  };

  const tagsList: tagItem[] = [];
  if (values.id) {
    //update
    if (isDate) {
      tagsList.push({
        tag_name: values.tagName,
        tag_start_date: convertTimestamp(values.startDate, values.startTime),
        tag_end_date: convertTimestamp(values.endDate, values.endTime),
        target_id: values.id,
      });
    } else {
      tagsList.push({
        tag_name: values.tagName,
        target_id: values.id,
      });
    }
    paragraphBody.field_campaign_events = tagsList;
  } else {
    //create
    if (isDate) {
      tagsList.push({
        tag_name: values.tagName,
        tag_start_date: convertTimestamp(values.startDate, values.startTime),
        tag_end_date: convertTimestamp(values.endDate, values.endTime),
      });
    } else {
      tagsList.push({
        tag_name: values.tagName,
      });
    }
    paragraphBody.field_campaign_events = tagsList;
  }

  return paragraphBody;
};

export const formatDisplayEventDate = (startDate: Date, endDate: Date) => {
  const startMonth = startDate.toLocaleString('en-US', { month: 'short' });
  const endMonth = endDate.toLocaleString('en-US', { month: 'short' });
  const startDay = startDate.getDate();
  const endDay = endDate.getDate();
  const startYear = startDate.getFullYear();

  if (startMonth === endMonth) {
    return `${startMonth} ${startDay}-${endDay}, ${startYear}`;
  } else {
    return `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${startYear}`;
  }
};

export const formatDisplayEventDateTime = (startDate: string, endDate: string) => {
  if ((startDate || endDate) === '') {
    return '';
  }
  const start = new Date(startDate);
  const end = new Date(endDate);

  const startMonth = start.toLocaleString('default', { month: 'short' });

  const startDay = start.getDate();
  const endDay = end.getDate();

  const year = start.getFullYear();

  const endTime = end.toLocaleString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  const formattedStartDate = `${startMonth}. ${startDay}`;
  const formattedEndDate = `${endDay}`;

  return `${formattedStartDate} - ${formattedEndDate}, ${year} ${endTime}`;
};

export const checkIfWithinEventDates = (start: string, end: string) => {
  // Get the current date and time
  const currentDate = new Date();
  // Define the start date and end date
  const startDate = new Date(start);
  const endDate = new Date(end);

  // Check if the current date is within the interval
  const isWithinRange = isWithinInterval(currentDate, {
    start: startDate,
    end: endDate,
  });

  return isWithinRange;
};

export const isValidDate = (date?: string) => {
  if (date === '0') {
    return false;
  } else {
    return true;
  }
};

export const handleSMS = (phoneNumber: string, isApple: boolean, msg: string) => {
  const separator = isApple ? '&' : '?';
  let msgx = escapeAmpersandInUrl(msg ? msg : '');
  const url =
    window.location.origin + `/sms?phone=${phoneNumber}${separator}body=${msgx}`;

  if (checkQCardRNAppDevice()) {
    postMessageToQCardApp('sendSMS', {
      phoneNumber,
      body: msg ? msg : '',
    });
  } else {
    const url =
      window.location.origin + `/sms?phone=${phoneNumber}${separator}body=${msgx}`;
    window.open(url, '_blank');
  }
};
