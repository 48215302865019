import { useSnackbar } from 'hooks/useSnackbar';
import { ProfileProps } from 'types/profile';
import {
  checkQCardRNAppDevice,
  postMessageToQCardApp,
} from 'utils/qcardReactNativeHandlers';
import { escapeAmpersandInUrl } from './string';
import { handleSMS } from './events';
import { isSafari, isAndroidDevice } from 'utils/device';
type CustomizationType = {
  emailSubject: string;
  emailSMSBody: string;
};

export const fieldCustomizations: Record<string, CustomizationType> = {
  Email: {
    emailSMSBody:
      'Hi, click the link to send an email to [Name of Card Owner].[Link]',
    emailSubject: 'Email Address of [Name of Card Owner]',
  },
  'Phone Number': {
    emailSMSBody:
      'Hi, click the link to call the [Phone Label set by user] Phone Number of [Name of Card Owner]. [Link]',
    emailSubject: '[Phone Label set by user] Phone Number of [Name of Card Owner] ',
  },
  Address: {
    emailSMSBody:
      'Hi, click the link to view the [Label set by user] of [Name of Card Owner]. [Link]',
    emailSubject: '[Label set by user] of [Name of Card Owner] ',
  },
  Website: {
    emailSMSBody:
      'Hi, click the [Label set by user] link shared by [Name of Card Owner] to view. [Link]',
    emailSubject: '[Label set by user] shared by [Name of Card Owner]',
  },
  'Custom Link': {
    emailSMSBody:
      'Hi, click the [Label set by user] link shared by [Name of Card Owner] to view. [Link]',
    emailSubject: '[Label set by user] shared by [Name of Card Owner]',
  },
  'Custom Section': {
    emailSMSBody:
      'Hi, click the [Label set by user] link shared by [Name of Card Owner] to view. [Link]',
    emailSubject: '[Label set by user] shared by [Name of Card Owner]',
  },
  'Resource Link': {
    emailSMSBody:
      'Hi, click the [Label set by user] link shared by [Name of Card Owner] to view. [Link]',
    emailSubject: '[Label set by user] shared by [Name of Card Owner]',
  },
  "Colleague's Profile": {
    emailSMSBody:
      'Hi, click the link to view the full digital business card of [Name of Colleague] shared by [Name of Card Owner]. [Link]',
    emailSubject:
      'Digital Business Card of [Name of Colleague] shared by [Name of Card Owner]',
  },
  'Action Button': {
    emailSMSBody:
      'Hi, click the [Label set by user] link shared by [Name of Card Owner] to view. [Link]',
    emailSubject: '[Label set by user] shared by [Name of Card Owner]',
  },
  'Digital Business Card': {
    emailSMSBody:
      'Hi, click the link to view the full digital business card of [Name of Card Owner].[Business Card Link]',
    emailSubject: 'Full Digital Business Card of [Name of Card Owner]',
  },
  'Social Platform': {
    emailSMSBody:
      'Hi, click the link to connect with [Name of Card Owner] on [Social Platform]. [Link]',
    emailSubject: '[Social Platform] Profile of [Name of Card Owner]',
  },
  Tags: {
    emailSMSBody:
      'Hi, click the link to view the [Tag Name] tag of [Name of Card Owner]. [Link]',
    emailSubject: '[Tag Name] tag of [Name of Card Owner]',
  },
  'Professional Profile': {
    emailSMSBody:
      'Hi, click the link to view the professional profile of [Name of Card Owner]. [Link]',
    emailSubject: 'Professional Profile of [Name of Card Owner]',
  },
  Award: {
    // Award and ccertification Email/Sms body
    emailSMSBody:
      'Hi, click the link to view the award/certification "[NAME OF AWARD]" of [Name of Card Owner]. [Link]',
    emailSubject: 'Award/Certification of [Name of Card Owner]',
  },
  'Downloadable Contact Card (Works Offline)': {
    emailSMSBody:
      'Hi, click the link to view the full digital business card of [Name of Card Owner].[Business Card Link]',
    emailSubject: 'Full Digital Business Card of [Name of Card Owner]',
  },
  'Share Contact Detail': {
    emailSMSBody:
      'Hi, click the link to view the full digital business card of [Name of Colleague].[Colleague Business Card Link]',
    emailSubject: 'Full Digital Business Card of [Name of Colleague]',
  },
};

// Utility function to replace placeholders in a given string
export const replacePlaceholders = (
  str: string,
  profile: ProfileProps | null,
  shareLabel: string,
  linkToBeShared: string,
  title: string
): string => {
  const getTitlePart = (index: number) => {
    return title && title.includes(':') ? title.split(':')[index].trim() : '';
  };

  return str
    .replace(
      '[Name of Card Owner]',
      `${profile?.field_first_name ?? ''} ${profile?.field_last_name ?? ''}`
    )
    .replace('[Name of Colleague]', getTitlePart(1))
    .replace('[Label set by user]', shareLabel)
    .replace(
      '[Phone Label set by user]',
      `${shareLabel[0]?.toUpperCase()}${shareLabel.slice(1).toLowerCase()}`
    )
    .replace('[Link]', linkToBeShared)
    .replace('[Name of Colleague]', shareLabel)
    .replace('[Social Platform]', shareLabel)
    .replace('[Tag Name]', shareLabel)
    .replace('[Add to contacts link]', shareLabel)
    .replace('[Business Card Link]', `${profile?.metatag.canonical_url ?? ''}`)
    .replace('[Colleague Business Card Link]', `${linkToBeShared}`)
    .replace('[NAME OF AWARD]', getTitlePart(1));
};

function isIPhoneFirefox() {
  const userAgent = navigator.userAgent;
  const isIPhone = /iPhone/.test(userAgent);
  const isFirefox = /FxiOS/.test(userAgent);
  return isIPhone && isFirefox;
}

export const handleShareEmail = (
  profile: ProfileProps,
  title: string,
  linkToBeShared: string,
  shareLabel: string,
  vcf?: boolean | undefined | null
) => {
  const customization = fieldCustomizations[title?.split(': ')[0]];

  if (customization) {
    let emailSMSBody = replacePlaceholders(
      customization.emailSMSBody,
      profile,
      shareLabel,
      linkToBeShared,
      title
    );

    if (
      (title === 'Professional Profile' && shareLabel) ||
      (title === 'Downloadable Contact Card (Works Offline)' && vcf === true)
    ) {
      emailSMSBody = `${emailSMSBody}?vcf=true`;
    }
    const emailSubject = replacePlaceholders(
      customization.emailSubject,
      profile,
      shareLabel,
      linkToBeShared,
      title
    );

    const isIphoneFirefox = isIPhoneFirefox();
    const url = `${
      isIphoneFirefox ? '/email' : 'mailto:'
    }?subject=${encodeURIComponent(
      isIphoneFirefox ? escapeAmpersandInUrl(emailSubject) : emailSubject
    )}&body=${encodeURIComponent(
      isIphoneFirefox ? escapeAmpersandInUrl(emailSMSBody) : emailSMSBody
    )}`;
    window.open(url, '_blank');
  }
};

export const handleShareText = (
  profile: ProfileProps,
  number: Number | String,
  title: string,
  linkToBeShared: string,
  shareLabel: string,
  isApple: boolean,
  vcf?: boolean | undefined | null
) => {
  const customization = fieldCustomizations[title?.split(': ')[0]];

  if (customization) {
    let smsBody = replacePlaceholders(
      customization.emailSMSBody,
      profile,
      shareLabel,
      linkToBeShared,
      title
    );
    if (
      (title === 'Professional Profile' && shareLabel) ||
      (title === 'Downloadable Contact Card (Works Offline)' && vcf === true)
    ) {
      smsBody = `${smsBody}%3Fvcf=true`;
    }
    const separator = isApple ? '&' : '?';
    const url = `${
      window.location.origin
    }/sms?phone=${number}${separator}body=${encodeURIComponent(
      escapeAmpersandInUrl(smsBody)
    )}`;

    if (checkQCardRNAppDevice()) {
      handleSMS(number + '', isApple, smsBody);
    } else {
      window.open(url, '_blank');
    }
  }
};

export const useHandleShareLink = (
  profile: ProfileProps | null,
  linkToBeShared: string,
  isAndroid: boolean
) => {
  const { setSnackbar } = useSnackbar();

  const showCopyMessage = () => {
    setSnackbar({
      open: true,
      message: 'Copied to Clipboard',
    });
  };

  const shareLink = () => {
    if (profile) {
      if (checkQCardRNAppDevice()) {
        postMessageToQCardApp('copyToClipboard', {
          text: linkToBeShared,
        });
        if (!isAndroid) {
          // Android app showing default system messaage
          showCopyMessage();
        }
      } else {
        navigator.clipboard.writeText(linkToBeShared);
        showCopyMessage();
      }
    }
  };

  return shareLink;
};

export const useHandleDownloadLink = (
  profile: ProfileProps | null,
  linkToBeShared: string,
  isAndroid: boolean
) => {
  const { setSnackbar } = useSnackbar();

  const showCopyMessage = () => {
    setSnackbar({
      open: true,
      message: 'Downloading Offline VCF Card',
    });
  };

  const shareLink = () => {
    if (profile) {
      const unixTimestamp = Math.floor(Date.now() / 1000);
      const blob = new Blob([linkToBeShared], {
        type:
          isSafari() || isAndroidDevice()
            ? 'text/vcard'
            : 'data:text/x-vcard;charset=utf-8,',
      });

      postMessageToQCardApp('sendVcfToMobile', {
        data: linkToBeShared,
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `QCard_${
        profile.field_first_name +
        (profile.field_last_name ? ' ' + profile.field_last_name : '')
      }-${unixTimestamp}.vcf`;

      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      showCopyMessage();

      return true;
    }
  };

  return shareLink;
};
