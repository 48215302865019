import * as React from 'react';

interface Props {
  fill?: string;
  height?: string;
  width?: string;
}

const LinkIcon: React.FC<Props> = ({
  fill = '#8544D8',
  height = '24px',
  width = '24px',
}) => {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5903 13.4101C11.0003 13.8001 11.0003 14.4401 10.5903 14.8301C10.2003 15.2201 9.56031 15.2201 9.17031 14.8301C7.22031 12.8801 7.22031 9.71007 9.17031 7.76007L12.7103 4.22007C14.6603 2.27007 17.8303 2.27007 19.7803 4.22007C21.7303 6.17007 21.7303 9.34007 19.7803 11.2901L18.2903 12.7801C18.3003 11.9601 18.1703 11.1401 17.8903 10.3601L18.3603 9.88007C19.5403 8.71007 19.5403 6.81007 18.3603 5.64007C17.1903 4.46007 15.2903 4.46007 14.1203 5.64007L10.5903 9.17007C9.41031 10.3401 9.41031 12.2401 10.5903 13.4101ZM13.4103 9.17007C13.8003 8.78007 14.4403 8.78007 14.8303 9.17007C16.7803 11.1201 16.7803 14.2901 14.8303 16.2401L11.2903 19.7801C9.34031 21.7301 6.17031 21.7301 4.22031 19.7801C2.27031 17.8301 2.27031 14.6601 4.22031 12.7101L5.71031 11.2201C5.70031 12.0401 5.83031 12.8601 6.11031 13.6501L5.64031 14.1201C4.46031 15.2901 4.46031 17.1901 5.64031 18.3601C6.81031 19.5401 8.71031 19.5401 9.88031 18.3601L13.4103 14.8301C14.5903 13.6601 14.5903 11.7601 13.4103 10.5901C13.0003 10.2001 13.0003 9.56007 13.4103 9.17007Z"
        fill={fill}
      />
    </svg>
  );
};

export default LinkIcon;
