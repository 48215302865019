import {
  PropsWithChildren,
  ReactElement,
  Suspense,
  useEffect,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import { useNavigate, matchPath, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { useLayout } from 'hooks/useLayout';
import { LoadingModal } from './LoadingModal';
import { SideMenu } from './SideMenu';
import { LoginModal } from './LoginModal';
import { useProfile } from 'hooks/useProfile';

const useStyles = makeStyles((theme) => ({
  container: {
    // background: theme.palette.common.white,
    width: '100%',
    height: '100vh',
    // overflowY: 'scroll',
    position: 'relative',
    zIndex: 0,
    display: 'flex',
    flexDirection: 'column',

    '@media screen and (max-height: 500px) and (orientation: landscape)': {
      height: '100vh',
    },
  },

  containerFullProfile: {
    width: '100%',
    height: '65vh',
    position: 'relative',
    zIndex: 0,
    display: 'flex',
    flexDirection: 'column',

    '@media screen and (max-height: 500px) and (orientation: landscape)': {
      height: '100vh',
    },
  },
  content: {
    width: '100%',
    height: '100%',
  },
  landscapeContent: {
    '@media screen and (max-height: 500px) and (orientation: landscape)': {
      background: theme.palette.common.black,
    },
  },
}));

const LoadingMessage = () => <div>Loading...</div>;

export const Layout = ({ children }: PropsWithChildren<{}>): ReactElement => {
  const classes = useStyles();
  const [isLogin, setIsLogin] = useState(false);

  const { loading, openMenu, setOpenMenu, showLoginModal, setShowLoginModal } =
    useLayout();
  const navigate = useNavigate();
  const location = useLocation();
  const { companyEntity, companyTheme } = useProfile();
  const isMatch = matchPath('/c/:username', location.pathname);

  useEffect(() => {
    if (showLoginModal) {
      openLogin();
    }
  }, [showLoginModal]);

  const closeMenu = () => {
    setOpenMenu(false);
  };

  const openLogin = () => {
    setOpenMenu(false);
    setIsLogin(true);
  };

  const closeLogin = () => {
    setIsLogin(false);
    setShowLoginModal(false);
  };

  const redirect = (alias: string) => {
    navigate(alias);
  };

  return (
    <Suspense fallback={<LoadingMessage />}>
      <div
        className={
          companyEntity?.fullProfileHeaderLayout
            ? classes.containerFullProfile
            : classes.container
        }
        style={{
          backgroundColor: isMatch ? companyTheme?.bgProfileColor : 'inherit',
        }}
      >
        <div
          className={classNames(
            classes.content,
            isMatch?.pattern?.end &&
              companyEntity?.paperviewLayout &&
              classes.landscapeContent
          )}
        >
          {children}
        </div>
        <SideMenu open={openMenu} closeMenu={closeMenu} openLogin={openLogin} />
        <LoginModal
          isOpen={isLogin}
          closeModal={closeLogin}
          redirect={redirect}
          setShowPage={setIsLogin}
        />
      </div>
      <LoadingModal loading={loading} color={companyTheme?.iconColor} />
    </Suspense>
  );
};
