import React, { ReactNode } from 'react';
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import { spacingScale } from '../constants/_theme/spacingScale';
import { typescale } from '../constants/_theme/typography';
import { DefaultColors } from 'constants/colors';
// import { getValueFromSession } from 'utils/storage';
import { ColorProps } from 'types/theme';
import { muiButton, companyCTAButton } from '../constants/_theme/muiButton';
import { muiTextField } from '../constants/_theme/muiTextField';
import {
  muiAccordion,
  muiAccordionDetails,
  muiAccordionSummary,
} from '../constants/_theme/muiAccordion';
import { muiTooltip } from '../constants/_theme/muiTooltip';
import { useProfile } from './useProfile';

// using module augmentation to override default theme type
declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles' {
  interface Components {
    CompanyCTAButton: Components<Omit<Theme, 'components'>> | {};
  }
}

const createMuiTheme = (
  colors: ColorProps,
  primaryColor: string = DefaultColors.BLACK
) =>
  createTheme({
    palette: {
      primary: {
        main: colors?.PRIMARY,
        light: colors?.PRIMARY_LIGHT,
        dark: colors?.PRIMARY_DARK,
      },
      error: {
        main: colors?.WARNING,
        light: colors?.WARNING_LIGHT,
      },
      text: {
        primary: colors?.BODY_TEXT,
        secondary: colors?.SECONDARY_TEXT,
      },
      success: {
        main: colors?.SUCCESS,
      },
      grey: {
        50: colors?.GREY,
        100: colors?.GREY_100,
        200: colors?.GREY_200,
        300: colors?.GREY_300,
      },
      common: {
        white: colors?.WHITE,
        black: colors?.BLACK,
      },
    },
    typography: typescale(primaryColor),
    spacing: spacingScale,
    components: {
      MuiButton: muiButton,
      MuiTextField: muiTextField,
      MuiTooltip: muiTooltip,
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            padding: '18px 16px',
          },
        },
      },
      MuiAccordion: muiAccordion,
      MuiAccordionSummary: muiAccordionSummary,
      MuiAccordionDetails: muiAccordionDetails,
      // custom components
      CompanyCTAButton: companyCTAButton,
    },
  });

interface Props {
  children?: ReactNode;
}

export const ThemeProvider: React.FC<Props> = ({ children }) => {
  // const branchId = getValueFromSession('branchId'); // commented since it is not used at the moment
  const { companyTheme } = useProfile();
  const theme = createMuiTheme(DefaultColors, companyTheme?.primaryColor);

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};
