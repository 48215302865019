import { useTheme } from '@mui/styles';
import { Typography } from '@mui/material';
import logo from '../../images/QCardLogo.svg';
import { Contents, PageContainer } from './styles/Page404.styles';
import NavigateToMyCard from 'components/NavigateToMyCard';

/**
 * Creates 404 page
 * @params none
 * @returns 404 page
 */

function Page404() {
  const theme = useTheme();

  return (
    <div className="mainWrapper">
      <div
        className="topBar"
        style={{
          width: '100%',
          height: 40,
          background: theme.palette.primary.main,
        }}
      ></div>
      <PageContainer>
        <NavigateToMyCard label={'Go Back'} />
        <Contents className="contents">
          <Typography
            variant="h5"
            sx={{
              fontSize: 72,
              lineHeight: '72px',
              fontWeight: 600,
              color: '#000',
            }}
          >
            404
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontSize: 24,
              lineHeight: '32px',
              fontWeight: 500,
              color: '#1f1f1f',
              marginTop: '24px',
            }}
          >
            Oops! This QCard page doesn't exist.
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontSize: 12,
              lineHeight: '20px',
              fontWeight: 400,
              color: '#1f1f1f',
              marginTop: '16px',
            }}
          >
            You may have mistyped the address or the account may have been removed.
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontSize: 12,
              lineHeight: '20px',
              fontWeight: 400,
              color: '#1f1f1f',
              marginTop: '40px',
            }}
          >
            Need assistance?{' '}
            <a
              href="https://forms.gle/Wm6sqKq2SPeq3Lvo6"
              style={{ fontWeight: 600, color: '#1f1f1f' }}
            >
              Click here
            </a>{' '}
            and tell us how we can help.
          </Typography>
          <img
            src={logo}
            style={{
              width: 73,
              marginTop: 50,
            }}
            alt=""
          />
        </Contents>
      </PageContainer>
    </div>
  );
}
export default Page404;
