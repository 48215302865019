import Modal from 'react-modal';
import { PropsWithChildren } from 'react';
import { LoginModalProps } from './LoginModal';
import classNames from 'classnames';
import { ReactComponent as CloseIcon } from 'images/icons/Close.svg';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { Button } from './Button';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    margin: 20,
    background: 'white',
    boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.15)',
    borderRadius: 16,
    padding: 40,
  },
  closeIcon: {
    position: 'absolute',
    right: 24,
    top: 24,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '104px 0',
    gap: '16px',
  },
  closeButton: {
    '&.MuiButton-root': {
      justifyContent: 'center',
      '& > p': {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: '20px',
        textTransform: 'capitalize',
        color: theme.palette.text.primary,
      },
    },
  },
}));

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: 'none',
    background: 'transparent',
    borderRadius: 16,
    width: '100%',
    maxWidth: 540,
  },
};
export const ConfirmationMessageModal = ({
  isOpen,
  closeModal,
  redirect,
  className,
}: PropsWithChildren<LoginModalProps>) => {
  const classes = useStyles();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="QRCode Modal"
      ariaHideApp={false}
    >
      <div className={classNames(classes.container, className)}>
        {closeModal && (
          <CloseIcon
            cursor="pointer"
            width={20}
            height={20}
            className={classes.closeIcon}
            onClick={closeModal}
          />
        )}
        <div className={classes.textContainer}>
          <Typography
            fontSize={30}
            lineHeight="40px"
            fontWeight={600}
            whiteSpace="nowrap"
          >
            Check Your Email
          </Typography>
          <Typography
            fontSize={14}
            lineHeight="24px"
            fontWeight={400}
            style={{ textAlign: 'center' }}
          >
            If we have your information in our system,
            <br /> we have sent you an email.
          </Typography>
          <Button
            variant="text"
            title="Close"
            onClick={closeModal}
            className={classes.closeButton}
          />
        </div>
      </div>
    </Modal>
  );
};
