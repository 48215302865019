import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  analyticsUserProfileAPI,
  trackUserProfileAPI,
  trackAddToContactsAPI,
  getProfileReportAPI,
  trackAddToWalletAPI,
  trackPageViewsAPI,
  trackClickToZoomBG,
  trackPurchaseOnMetaVerseAPI,
  trackClickToDownloadedAsset,
  trackClickOfflineQRCode,
  trackCustomButtonTapped,
  CustomButtonType,
} from 'apis/analytics';
import { ProfileProps } from 'types/profile';
import {
  AnalyticsParamsValue,
  AnalyticsProps,
  AnalyticsTrackEvent,
  DateRangeType,
  PageViews,
  ProfileReport,
  SourceTypes,
} from 'types/analytics';
import { subDays, format, subYears } from 'date-fns';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  getAuthWithExpire,
  getValueFromLocal,
  setValueInLocal,
} from 'utils/storage';
import { getIPAddress, getIPConfigAddress } from 'apis/profile';
import { useProfile } from 'hooks/useProfile';
import { getUserPassResetByUID } from 'apis/getUserDataFromResetPassApi';

export type ViewScreens =
  | 'profile'
  | 'metaverse'
  | 'analytics'
  | 'emailSig'
  | 'widgets'
  | 'eventTags'
  | 'zoomBg'
  | 'appleWallet'
  | 'help'
  | 'feedback'
  | 'googleWallet'
  | 'findColleagues'
  | 'impersonateUser'
  | 'downloadableAssets';

interface UserData {
  activationUserFName: string | null;
  activationUserLName: string | null;
  activationUserCompany: string | null;
  activationUserEmail: string | null;
}

const initialProfileReport = {
  entryPoints: [],
  profileViews: {
    data: [],
    totals: 0,
  },
  addToContacts: {
    data: [],
    totals: 0,
  },
  uniqueVisitedProfiles: {
    data: [],
    totals: 0,
  },
};

const initScreenViews = {
  profile: false,
  metaverse: false,
  analytics: false,
  emailSig: false,
  widgets: false,
  eventTags: false,
  zoomBg: false,
  help: false,
  feedback: false,
  appleWallet: false,
  googleWallet: false,
  findColleagues: false,
  impersonateUser: false,
  downloadableAssets: false,
};

export const useAnalytics = (profile: ProfileProps | null) => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [searchParams] = useSearchParams();
  const [profileReport, setProfileReport] =
    useState<ProfileReport>(initialProfileReport);

  const userTrack = getValueFromLocal('trackScreens');
  const userProfiles = getValueFromLocal('trackProfiles');
  const { uid } = useParams();

  const currentUser = getAuthWithExpire()?.user;
  const company = currentUser?.company ?? '';
  const userName = currentUser?.field_first_name
    ? currentUser?.field_first_name +
      (currentUser?.field_last_name ? ' ' + currentUser?.field_last_name : '')
    : '';
  const activeUserName = userData?.activationUserFName
    ? userData?.activationUserFName +
      (userData?.activationUserLName ? ' ' + userData?.activationUserLName : '')
    : '';
  const { userAgent, platform } = navigator;
  useEffect(() => {
    if (uid) {
      const fetchProfileData = async () => {
        const data = await getUserPassResetByUID(uid);
        if (data) {
          const extractValue = (
            field: Array<{ value: string }> | undefined
          ): string | null => (field && field.length > 0 ? field[0].value : null);
          const activationUserFName = extractValue(data?.user?.field_first_name);
          const activationUserLName = extractValue(data?.user?.field_last_name);
          const activationUserCompany = data.company?.name || null;
          const activationUserEmail = extractValue(data.user?.mail);
          setUserData({
            activationUserFName,
            activationUserLName,
            activationUserCompany,
            activationUserEmail,
          });
        }
      };
      fetchProfileData();
    }
  }, [uid]);

  const initScreenTracking = useCallback(() => {
    if (!userTrack) {
      setValueInLocal('trackScreens', {
        user: `/user/${profile?.uid}`,
        screens: initScreenViews,
      });
    }
  }, [profile?.path.alias]);

  const trackScreenOnView = useCallback(
    (screen: ViewScreens) => {
      // check if the screen is visited or not
      if (!userTrack?.screens[screen]) {
        setValueInLocal('trackScreens', {
          ...userTrack,
          screens: {
            ...userTrack?.screens,
            [screen]: true,
          },
        });
      }
    },
    [userTrack]
  );

  const setAnalyticsUserProfile = useCallback(async () => {
    if (!profile) return;

    if (!userTrack?.screens.profile) {
      const ipConfig = await getIPConfigAddress();
      const analyticsProfile: AnalyticsProps = {
        userAlias: `/user/${profile.uid}`,
        firstName: profile.field_first_name,
        lastName: profile.field_last_name,
        email: profile.mail,
        baseURL: process.env.REACT_APP_BASE_API,
        city: ipConfig?.city ?? '',
        country: ipConfig?.country_name ?? '',
        region: ipConfig?.region ?? '',
      };
      await analyticsUserProfileAPI(analyticsProfile);
    }
  }, [profile, userTrack?.screens.profile]);

  const trackProfileView = useCallback(
    async (profile: any) => {
      const userID = profile.uid;
      const distinctId = userID ? `/user/${userID}` : '';
      if (!!distinctId && !userProfiles?.visitedProfiles[distinctId]) {
        const visitedUserProfile: AnalyticsProps = {
          userAlias: distinctId,
          userId: profile?.uid ?? 'anonymous',
          user: profile?.field_first_name
            ? `${profile?.field_first_name} ${profile?.field_last_name ?? ''}`
            : '',
        };
        const userVisitedProfiles = {
          visitedProfiles: { [distinctId]: visitedUserProfile },
        };
        if (currentUser && `/user/${currentUser?.uid}` !== distinctId) {
          const currentUserDistinctId = `/user/${currentUser?.uid}`;
          const currentUserProfile: AnalyticsProps = {
            userAlias: currentUserDistinctId,
            userId: currentUser?.uid,
            company: company ?? '',
            user: userName ?? '',
          };
          userVisitedProfiles.visitedProfiles[currentUserDistinctId] =
            currentUserProfile;
        }
        setValueInLocal('trackProfiles', userVisitedProfiles);

        const trackProfile: AnalyticsProps = {
          userAlias: distinctId ?? '',
          userId: currentUser?.uid ?? 'anonymous',
          company: currentUser ? company : '',
          user: currentUser ? userName : '',
          baseURL: process.env.REACT_APP_BASE_API,
        };
        const userUrlAliasInformation = currentUser?.alias;
        const ipConfig = await getIPAddress();
        trackProfile.ip = ipConfig?.ip ?? '';
        trackProfile.userAgent = userAgent;
        trackProfile.platform = platform;
        trackProfile.urlAlias = userUrlAliasInformation;

        const source = searchParams.get('source');
        if (source) {
          trackProfile.source = source as SourceTypes;
        }
        await trackUserProfileAPI(trackProfile);
      }
    },
    [searchParams, trackScreenOnView, userProfiles?.visitedProfiles]
  );

  const trackCustomButtonTappedEvent = async (
    buttonType: CustomButtonType,
    data?: AnalyticsProps
  ) => {
    const distinctId = !!currentUser?.uid
      ? `/user/${currentUser?.uid}`
      : '/user/anonymous';
    let sessionData = null;
    if (!activeUserName) {
      const sessionStoredData = sessionStorage.getItem(`userPassResetData_${uid}`);
      if (sessionStoredData) {
        sessionData = JSON.parse(sessionStoredData);
      }
    }
    const sessionActiveUserName =
      (sessionData?.user?.field_first_name?.[0]?.value || '') +
      ' ' +
      (sessionData?.user?.field_last_name?.[0]?.value || '');

    let trackProfile: AnalyticsProps = {
      userAlias: distinctId,
      userId: currentUser?.uid || uid || 'anonymous',
      company: currentUser
        ? company
        : userData?.activationUserCompany || sessionData?.company?.name || '',
      user: currentUser ? userName : activeUserName || sessionActiveUserName || '',
      baseURL: process.env.REACT_APP_BASE_API,
    };
    const ipConfig = await getIPAddress();
    trackProfile.ip = ipConfig?.ip ?? '';
    trackProfile.userAgent = userAgent;
    trackProfile.platform = platform;
    trackProfile.email =
      userData?.activationUserEmail || sessionData?.user?.mail?.[0]?.value || '';
    if (data) {
      trackProfile = { ...trackProfile, ...data };
    }
    await trackCustomButtonTapped(trackProfile, buttonType);
  };

  const trackClickEvent = useCallback(
    async (
      uid: string,
      event: AnalyticsTrackEvent,
      params?: AnalyticsParamsValue
    ) => {
      const ipConfig = await getIPAddress();
      const ip = ipConfig?.ip ?? '';
      const analyticsData: AnalyticsProps = {
        userAlias: `/user/${uid}`,
        user: userName,
        ip,
        userId: currentUser?.uid ?? 'anonymous',
        company,
        userAgent,
        platform,
        baseURL: process.env.REACT_APP_BASE_API,
      };

      if (event === 'add-to-contacts') {
        await trackAddToContactsAPI(analyticsData);
      } else if (event === 'click-zoom-bg') {
        await trackClickToZoomBG(analyticsData);
      } else if (event === 'downloaded-asset' && params?.downloadedAssetTitle) {
        analyticsData.downloadedAssetTitle = params.downloadedAssetTitle;
        await trackClickToDownloadedAsset(analyticsData);
      } else if (event === 'offline-qr-code') {
        await trackClickOfflineQRCode(analyticsData);
      } else if (event === 'purchase-metaverse' && params?.orderName) {
        analyticsData.orderName = params.orderName;
        await trackPurchaseOnMetaVerseAPI(analyticsData);
      } else if (event === 'add-to-wallet') {
        await trackAddToWalletAPI(analyticsData);
      }
    },
    []
  );

  const trackPageViews = useCallback(
    async (id: string, page: PageViews, extraInfo?: any) => {
      const pageToScreenMapping = {
        Analytics: 'analytics',
        'Apple Wallet': 'appleWallet',
        'Email Signature': 'emailSig',
        'On The Metaverse': 'metaverse',
        'Widgets Instructions': 'widgets',
        'Zoom Background': 'zoomBg',
        Help: 'help',
        Feedback: 'feedback',
        'Google Wallet': 'googleWallet',
        'Find Colleagues': 'findColleagues',
        'Impersonate User': 'impersonateUser',
        'Downloadable Assets': 'downloadableAssets',
        'Create New Account': 'createNewAccount',
      };

      const screenName = pageToScreenMapping[page];
      const ipConfig = await getIPAddress();
      const ip = ipConfig?.ip ?? '';
      const userId = currentUser?.uid ?? 'anonymous';
      if (
        (screenName && !userTrack?.screens[screenName]) ||
        page == 'Impersonate User'
      ) {
        trackScreenOnView(screenName as ViewScreens);
        const analyticsData: AnalyticsProps = {
          userAlias: `/user/${id}`,
          user: userName,
          page,
          ip,
          userId,
          company,
          userAgent,
          platform,
          baseURL: process.env.REACT_APP_BASE_API,
        };
        if (extraInfo) {
          analyticsData.extraInfo = extraInfo;
        }
        await trackPageViewsAPI(analyticsData);
      }
    },
    [trackScreenOnView, userTrack?.screens]
  );

  const getProfileReport = useCallback(
    async (user: string, dateRange: DateRangeType, eventId?: string) => {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const userName = user.replace('/c/', '');
      // get the previous days
      const today = new Date();
      let pastDate = null;
      if (dateRange === '7D') {
        pastDate = subDays(today, 7);
      } else if (dateRange === '30D') {
        pastDate = subDays(today, 30);
      } else if (dateRange === '12M') {
        pastDate = subYears(today, 1);
      } else {
        pastDate = subDays(today, 0);
      }

      const fromDate = format(pastDate, 'yyyy-MM-dd');
      const toDate = format(today, 'yyyy-MM-dd');

      const reportData = await getProfileReportAPI({
        user: userName,
        fromDate,
        toDate,
        dateRange,
        timeZone,
        campaignId: eventId,
      });

      setProfileReport(reportData);
    },
    []
  );

  return {
    setAnalyticsUserProfile,
    trackProfileView,
    trackClickEvent,
    getProfileReport,
    profileReport,
    trackPageViews,
    initScreenTracking,
    trackScreenOnView,
    trackCustomButtonTappedEvent,
  };
};
