import { PixelCrop } from 'react-image-crop';

export const readImage = (file: File, callback: any, uid?: number) => {
  const reader = new FileReader();
  reader.onload = function (event) {
    let binaryData = event.target?.result;
    let base64String = window.btoa(binaryData as string);
    callback(base64String);
  };
  reader.readAsBinaryString(file);
};

export const getBase64StringFromDataURL = (dataURL: any) =>
  dataURL.replace('data:', '').replace(/^.+,/, '');

export const convertImageUrlToDataURL = (url: string): Promise<string | null> =>
  new Promise((resolve, reject) => {
    if (!url.includes('null')) {
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result as string);
          };
          reader.readAsDataURL(blob);
        })
        .catch(() => reject(null));
    }
  });

export const getImageBaseUrl = ({ imageUrl }: { imageUrl: string }) => {
  if (!imageUrl) {
    return '';
  }
  return `${process.env.REACT_APP_BASE_API}${imageUrl}`;
};

const TO_RADIANS = Math.PI / 180;

function validateAndConvertImage(
  image: HTMLImageElement
): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    if (!image.complete || image.naturalWidth === 0) {
      reject(new Error('Image not loaded or invalid'));
      return;
    }

    const mimeType = image.src.split(';')[0].split(':')[1];
    if (mimeType === 'image/png') {
      resolve(image);
      return;
    }

    // Convert image to PNG
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      reject(new Error('No 2d context'));
      return;
    }

    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;

    ctx.drawImage(image, 0, 0);

    canvas.toBlob((blob) => {
      if (!blob) {
        reject(new Error('Image conversion to PNG failed'));
        return;
      }

      const newImage = new Image();
      newImage.src = URL.createObjectURL(blob);

      newImage.onload = () => {
        resolve(newImage);
      };
    }, 'image/png');
  });
}

export async function cropImage(
  image: HTMLImageElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0
): Promise<string> {
  try {
    const validImage = await validateAndConvertImage(image);

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      throw new Error('No 2d context');
    }

    // Set canvas size to the crop size
    canvas.width = crop.width;
    canvas.height = crop.height;

    const scaleX = validImage.naturalWidth / image.width;
    const scaleY = validImage.naturalHeight / image.height;

    ctx.imageSmoothingQuality = 'high';

    ctx.save();
    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate((rotate * Math.PI) / 180);
    ctx.scale(scale, scale);
    ctx.translate(-canvas.width / 2, -canvas.height / 2);

    ctx.drawImage(
      validImage,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    ctx.restore();

    const base64Image = canvas.toDataURL('image/jpeg');

    return base64Image;
  } catch (error) {
    throw new Error(`Image cropping failed: ${error}`);
  }
}
