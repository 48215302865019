import * as React from 'react';

interface Props {
  fill?: string;
  height?: string;
  width?: string;
}

const NearByIcon: React.FC<Props> = ({ height = '14px', width = '14px' }) => {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.64692 6.11978L3.70352 5.06318L2.9424 4.30206C2.65065 4.01032 2.17755 4.01032 1.8858 4.30206C1.59406 4.59381 1.59406 5.06691 1.8858 5.35866L2.64692 6.11978Z"
        fill="#8544D8"
      />
      <path
        d="M13.0735 4.34112C12.7818 4.04938 12.3087 4.04938 12.0169 4.34112L8.92595 7.43191C8.17866 8.17921 6.98721 8.21526 6.19527 7.54305L5.13867 8.59965C6.51522 9.85273 8.6527 9.81837 9.98255 8.48851L13.0735 5.39754C13.3653 5.10579 13.3653 4.63287 13.0735 4.34112Z"
        fill="#8544D8"
      />
      <path
        d="M13.1146 8.2494L12.3535 7.48828L11.2969 8.54488L12.058 9.306C12.3497 9.59774 12.8228 9.59774 13.1146 9.306C13.4063 9.01425 13.4063 8.54114 13.1146 8.2494Z"
        fill="#8544D8"
      />
      <path
        d="M8.80508 6.06489L9.86168 5.00829C8.48513 3.7552 6.34765 3.78957 5.01779 5.11942L1.92682 8.2104C1.63507 8.50214 1.63507 8.97525 1.92682 9.267C2.21856 9.55874 2.69167 9.55874 2.98342 9.267L6.07439 6.17602C6.82169 5.42872 8.01314 5.39268 8.80508 6.06489Z"
        fill="#8544D8"
      />
    </svg>
  );
};

export default NearByIcon;
