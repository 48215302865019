import * as React from 'react';

interface Props {
  fill?: string;
  height?: string;
  width?: string;
}

const AirDropIcon: React.FC<Props> = ({ height = '14px', width = '14px' }) => {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.61347 7.64164C7.61347 7.94794 7.36451 8.19678 7.05833 8.19678C6.75203 8.19678 6.5032 7.94819 6.5032 7.64164C6.5032 7.3351 6.75179 7.08651 7.05833 7.08651C7.36463 7.08676 7.61347 7.33522 7.61347 7.64164Z"
        fill="#8544D8"
      />
      <path
        d="M7.8811 9.45138C7.75237 9.45138 7.62746 9.38454 7.5595 9.26518C7.45765 9.08774 7.51905 8.8616 7.69662 8.76011C8.10181 8.52842 8.35286 8.10005 8.35286 7.64182C8.35286 6.92787 7.77197 6.34695 7.05799 6.34695C6.34442 6.34695 5.76312 6.92784 5.76312 7.64182C5.76312 8.10005 6.01454 8.52854 6.41999 8.76011C6.59694 8.86159 6.65847 9.08787 6.55711 9.26518C6.45526 9.44262 6.22973 9.50477 6.05204 9.40255C5.41761 9.03928 5.02302 8.36428 5.02302 7.64172C5.02302 6.51973 5.93613 5.60663 7.05811 5.60663C8.18047 5.60663 9.09319 6.51973 9.09319 7.64172C9.09319 8.36444 8.69924 9.03928 8.06456 9.40255C8.00635 9.43584 7.94337 9.45138 7.8811 9.45138Z"
        fill="#8544D8"
      />
      <path
        d="M8.61818 10.7406C8.48932 10.7406 8.36466 10.6737 8.29657 10.5544C8.19472 10.3769 8.25613 10.1508 8.43369 10.0493C9.29686 9.55509 9.83274 8.63248 9.83274 7.64182C9.83274 6.11168 8.58844 4.86703 7.05795 4.86703C5.5278 4.86703 4.2829 6.11171 4.2829 7.64182C4.2829 8.63248 4.81928 9.55506 5.68245 10.0493C5.86026 10.1508 5.92167 10.3771 5.81994 10.5544C5.71747 10.7318 5.49205 10.7917 5.31449 10.6915C4.22187 10.0653 3.54305 8.89685 3.54305 7.64159C3.54305 5.70345 5.12029 4.12662 7.05802 4.12662C8.99617 4.12662 10.573 5.70348 10.573 7.64159C10.573 8.89688 9.89406 10.0653 8.80156 10.6915C8.74348 10.7247 8.6802 10.7406 8.61818 10.7406Z"
        fill="#8544D8"
      />
      <path
        d="M9.35148 12.0247C9.22275 12.0247 9.09821 11.9578 9.03013 11.8385C8.92815 11.6611 8.98969 11.4349 9.16725 11.3334C10.4903 10.5758 11.3131 9.16076 11.3131 7.6417C11.3131 5.29577 9.40426 3.3868 7.05817 3.3868C4.71225 3.3868 2.80327 5.29562 2.80327 7.6417C2.80327 9.16073 3.62512 10.5758 4.9491 11.3334C5.12617 11.4349 5.18757 11.6612 5.08622 11.8385C4.98375 12.0158 4.75833 12.0763 4.58077 11.9756C3.02746 11.0861 2.06318 9.42588 2.06318 7.6415C2.06318 4.88717 4.30345 2.64668 7.05801 2.64668C9.81197 2.64668 12.0528 4.88729 12.0528 7.6415C12.0528 9.42588 11.0877 11.0861 9.53453 11.9756C9.4767 12.0086 9.41363 12.0247 9.35148 12.0247Z"
        fill="#8544D8"
      />
      <path
        d="M10.0854 13.3088C9.95671 13.3088 9.83217 13.242 9.76409 13.1227C9.66212 12.9453 9.72365 12.7191 9.90121 12.6176C11.6847 11.5962 12.7927 9.6895 12.7927 7.64201C12.7927 4.47964 10.2201 1.90726 7.05792 1.90726C3.89586 1.90738 1.32285 4.4797 1.32285 7.64201C1.32285 9.68953 2.43126 11.5964 4.21529 12.6176C4.39236 12.7191 4.45377 12.9453 4.35241 13.1227C4.24994 13.3001 4.02418 13.3616 3.84734 13.26C1.83372 12.1067 0.583008 9.95434 0.583008 7.64175C0.583008 4.07153 3.48814 1.16675 7.05801 1.16675C10.6285 1.16675 13.533 4.07153 13.533 7.64175C13.533 9.95427 12.2822 12.1069 10.269 13.26C10.2113 13.2929 10.1475 13.3088 10.0854 13.3088Z"
        fill="#8544D8"
      />
    </svg>
  );
};

export default AirDropIcon;
