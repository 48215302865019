import { Box } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { formatBackFlipLayout, formatPaperViewLayout } from 'utils/layout';
import { fullAddress } from 'utils/string';
import { CompanyEntity, useProfile } from 'hooks/useProfile';

interface MobileLandscapeProps {
  profileQRCode?: string | null;
  name?: string | null;
  role?: string | null;
  profileImgUrl?: string | null;
  companyImgUrl?: string | null;
  email: string | null;
  address: string | null;
  layout: string;
  backFlip: string;
  companyEntity: CompanyEntity | null;
}

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    position: 'relative',
    width: '599px',
    minHeight: '100%',
    margin: '0 auto',
    transformStyle: 'preserve-3d',
    transition: 'transform 0.5s',
    maxWidth: '100%',
  },

  cardFront: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.common.white,
    height: '100%',
    '& object': {
      height: '100%',
    },
  },

  cardBack: {
    backgroundColor: theme.palette.common.white,
    transform: 'rotateY(180deg)',
    width: '100%',
    height: '100%',

    '& object': {
      height: '100%',
    },
    display: 'flex',
    flexDirection: 'column',
  },

  flipCardFlipped: {
    transform: 'rotateY(180deg)',
  },

  cardPosition: {
    backfaceVisibility: 'hidden',
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },

  qrWrapper: {
    backgroundColor: 'white',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },

  companyLogo: {
    objectFit: 'contain',
  },
}));

const MobileLandscape = ({
  name,
  role,
  address,
  email,
  companyImgUrl,
  profileImgUrl,
  profileQRCode,
  layout,
  backFlip,
  companyEntity,
}: MobileLandscapeProps) => {
  const [flipped, setFlipped] = useState(false);
  const classes = useStyles();
  const [htmlLayout, setHtmlLayout] = useState<string>('');
  const [backFlipHtmlLayout, setBackFlipHtmlLayout] = useState<string>('');
  const { activeWebsite, profile, activePhoneNumber } = useProfile();
  const [contact, setContact] = useState('');
  const handleClick = (event: any) => {
    if (event?.target?.id !== 'preserve-click') {
      setFlipped(!flipped);
    }
  };
  useEffect(() => {
    setContact(activePhoneNumber);
  }, [activePhoneNumber]);

  useEffect(() => {
    const defaultCompanyLogoUrl = companyEntity?.companyEntityLogoUrl ?? '';
    const companyName = companyImgUrl ?? defaultCompanyLogoUrl;
    formatPaperViewLayout({
      paperViewLayout: layout,
      fullName: name ?? '',
      companyName,
      companyURL: activeWebsite,
      profilePicture: profileImgUrl ?? '',
      role: role ?? '',
      phone: contact ?? '',
      email: email ?? '',
      address: address ?? '',
      addressURL: profile ? fullAddress(profile).queryAddress : '',
      qrCodeURL: profileQRCode ?? '',
      companyEntity: companyEntity,
      fullAddress: profile ? fullAddress(profile).fullAddress : '',
    }).then((data) => setHtmlLayout(data));
  }, [
    activeWebsite,
    address,
    companyEntity,
    companyImgUrl,
    contact,
    email,
    layout,
    name,
    profile,
    profileImgUrl,
    profileQRCode,
    role,
  ]);

  useEffect(() => {
    formatBackFlipLayout({
      backFlipLayout: backFlip,
      profile: profile,
    }).then((data) => setBackFlipHtmlLayout(data));
  }, [backFlip, profile]);

  return (
    <Box
      className={`${classes.cardWrapper} ${flipped ? classes.flipCardFlipped : ''}`}
      onClick={handleClick}
    >
      <div className={classNames(classes.cardFront, classes.cardPosition)}>
        <object
          aria-label="Company layout"
          dangerouslySetInnerHTML={{
            __html: htmlLayout,
          }}
        ></object>
      </div>
      <div className={classNames(classes.cardBack, classes.cardPosition)}>
        <object
          aria-label="Backflip layout"
          dangerouslySetInnerHTML={{
            __html: backFlipHtmlLayout,
          }}
        ></object>
      </div>
    </Box>
  );
};

export default MobileLandscape;
