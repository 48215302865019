import * as React from 'react';

interface Props {
  fill?: string;
  height?: string;
  width?: string;
}

const LeftArrow: React.FC<Props> = ({
  fill = '#1F1F1F',
  height = '24px',
  width = '24px',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.625 13C20.1773 13 20.625 12.5523 20.625 12C20.625 11.4477 20.1773 11 19.625 11V13ZM8.66789 17.7071C9.05842 18.0976 9.69158 18.0976 10.0821 17.7071C10.4726 17.3166 10.4726 16.6834 10.0821 16.2929L8.66789 17.7071ZM4.375 12L3.66789 11.2929C3.27737 11.6834 3.27737 12.3166 3.66789 12.7071L4.375 12ZM10.0821 7.70711C10.4726 7.31658 10.4726 6.68342 10.0821 6.29289C9.69158 5.90237 9.05842 5.90237 8.66789 6.29289L10.0821 7.70711ZM19.625 11H5.625V13H19.625V11ZM10.0821 16.2929L5.08211 11.2929L3.66789 12.7071L8.66789 17.7071L10.0821 16.2929ZM5.08211 12.7071L10.0821 7.70711L8.66789 6.29289L3.66789 11.2929L5.08211 12.7071Z"
        fill={fill}
      />
    </svg>
  );
};

export default LeftArrow;
