export const reactNativeUserAgent = 'qCardApp' as const;

export const reactNativeIos = 'qCardAppiOS' as const;
export const reactNativeAndroid = 'qCardAppAndroid' as const;

export const appleDeivces = [
  'Mac',
  'iPod',
  'iPhone',
  'iPad',
  reactNativeIos,
] as const;

export const androidDevices = ['Android', 'android', reactNativeAndroid];

export const windowsBrowsers = ['Windows', 'Edg'];
