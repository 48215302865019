import { baseAPICreator } from './baseAPICreator';
import { ProfileProps } from 'types/profile';

export const updateParagraphAPI = async (
  body: any
): Promise<ProfileProps | null> => {
  const api = baseAPICreator({
    baseURL: '/paragraph-add',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  try {
    await api.post(`/add`, body);
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};
