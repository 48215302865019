import {
  QLink,
  CustomParagraph,
  AwardCertificationItem,
  RepeatablePhoneNumber,
  RepeatableEmailAddress,
  RepeatableWebsite,
  RepeatableWorkScheduleDay,
  Language,
  Testimonial,
} from 'types/profile';

export interface ColleagueType {
  link: string;
  is_visible?: boolean;
  comment?: string;
}

export interface FormValues {
  aboutMe?: string;
  firstName?: string;
  lastName?: string;
  position?: string;
  email?: string;
  companyName?: string;
  address?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  keywords?: string;
  customSections?: CustomParagraph[];
  languages?: Language[];
  testimonials?: Testimonial[];
  awardsCertifications?: AwardCertificationItem[];
  featuredLinks?: QLink[];
  resources?: QLink[];
  linkedIn?: string;
  facebook?: string;
  twitter?: string;
  instagram?: string;
  tiktok?: string;
  skype?: string;
  whatsapp?: string;
  viber?: string;
  telegram?: string;
  colleagues?: ColleagueType[];
  newAddedColleagues?: ColleagueType[];
  colleaguesStatement?: string;
  fieldColleagueComment?: ColleagueType[];
  field_colleagues_section_title?: string;
  fieldShortcutMobile?: boolean;
  fieldContactDetailsNumbers?: RepeatablePhoneNumber[];
  fieldMoreContactEmails?: RepeatableEmailAddress[];
  fieldRepeatableWebsites?: RepeatableWebsite[];
  pronouns: string;
  pronounsCustom: string;
  aboutme_section_title?: string;
  field_resources_section_title?: string;
  field_languages_section_title?: string;
  field_testimonials_section_title?: string;
  field_work_sched_sect_title?: string;
  field_awards_title: string;
  field_websites_section_title?: string;
  field_social_links_section_title?: string;
  field_show_home_timezone?: boolean;
  field_home_timezone: string;
  field_show_traveling_timezone?: boolean;
  field_traveling_timezone: string;
  field_begin_travel_day: string;
  field_end_travel_day: string;
  field_show_work_schedule?: boolean;
  field_work_schedule_days: RepeatableWorkScheduleDay[];
  field_show_alert_msg?: boolean;
  field_alert_message?: string;
  field_work_schedule_alert?: string | boolean;
}

export enum ContactDetailsTypes {
  MOBILE = 'mobile',
  HOME = 'home',
  WORK = 'work',
  OOO = 'ooo',
  PHONE = 'phone',
  TOLLFREE = 'tollfree',
  FAX = 'fax',
  PAGER = 'pager',
  OTHER = 'other',
}

interface ContactDetailRecordType {
  label: string;
  value: ContactDetailsTypes;
  icon: string;
}

export const contactDetailsList: Record<
  ContactDetailsTypes,
  ContactDetailRecordType
> = {
  [ContactDetailsTypes.MOBILE]: {
    label: 'Mobile',
    value: ContactDetailsTypes.MOBILE,
    icon: 'Mobile',
  },
  [ContactDetailsTypes.HOME]: {
    label: 'Home',
    value: ContactDetailsTypes.HOME,
    icon: 'Home',
  },
  [ContactDetailsTypes.WORK]: {
    label: 'Work',
    value: ContactDetailsTypes.WORK,
    icon: 'Phone',
  },
  [ContactDetailsTypes.OOO]: {
    label: 'Out of Office',
    value: ContactDetailsTypes.OOO,
    icon: 'Phone',
  },
  [ContactDetailsTypes.PHONE]: {
    label: 'Phone',
    value: ContactDetailsTypes.PHONE,
    icon: 'Phone',
  },
  [ContactDetailsTypes.TOLLFREE]: {
    label: 'Toll-Free',
    value: ContactDetailsTypes.TOLLFREE,
    icon: 'Phone',
  },
  [ContactDetailsTypes.FAX]: {
    label: 'Fax',
    value: ContactDetailsTypes.FAX,
    icon: 'Fax',
  },
  [ContactDetailsTypes.PAGER]: {
    label: 'Pager',
    value: ContactDetailsTypes.PAGER,
    icon: 'Pager',
  },
  [ContactDetailsTypes.OTHER]: {
    label: 'Other',
    value: ContactDetailsTypes.OTHER,
    icon: 'Phone',
  },
};

export enum Pronouns {
  NONE = 'None',
  HE_HIM = 'He/Him',
  SHE_HER = 'She/Her',
  THEY_THEM = 'They/Them',
  CUSTOM = 'Custom',
}
