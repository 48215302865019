import React, { useEffect, useState } from 'react';
import { Button } from 'components/Button';
import { Helmet } from 'react-helmet';
import { Form, Formik, FormikHelpers } from 'formik';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as Yup from 'yup';
import classNames from 'classnames';
import NavigateToMyCard from 'components/NavigateToMyCard';
import CreateAccountForm from './CreateAccountForm';
import { getCompaniesListing, getCompanyApiKey } from 'apis/profile';
import { NavigationBar } from 'components/NavigationBar';
import { useLayout } from 'hooks/useLayout';
import { useProfile } from 'hooks/useProfile';
import { Company } from 'types/companyDirectory';
import { getValueFromSession } from 'utils/storage';
import { EMAIL_REGEX, FormValues } from './utils/types';
import { makeStyles, useTheme } from '@mui/styles';
import Page404 from 'pages/Page404';
import { LoadingModal } from 'components/LoadingModal';
import { useFeatureFlag } from 'configcat-react';
import { addUserAPI } from 'apis/addUser';
import { AddUserProps } from 'types/addUser';
import { useSnackbar } from 'hooks/useSnackbar';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    background: theme.palette.grey[300],
  },
  content: {
    width: '100%',
    maxWidth: 1048,
    padding: '78px 24px 120px',
    margin: 'auto',
  },
  embeddedContent: {
    paddingTop: 130,
  },
  navigateButtonsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mainContent: {
    width: '100%',
    maxWidth: 800,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    '@media screen and (min-width: 576px)': {
      gap: 24,
    },
  },
  actions: {
    width: '100%',
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 16,
    filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1))',
    background: theme.palette.common.white,
    position: 'fixed',
    bottom: 0,
    zIndex: 100,

    '@media screen and (min-width: 576px)': {
      padding: '18px 24px',
      gap: 40,
    },
    '& .MuiButton-root': {
      maxWidth: 320,
    },
  },
  ctaTitle: {
    '&.MuiTypography-root': {
      fontWeight: 700,
    },
  },
}));

const CreateNewAccount = () => {
  const [companies, setCompanies] = useState<Company[] | null>([]);
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [companyApiKey, setCompanyApiKey] = useState<string | null>(null);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isCompanyAPILoading, setIsCompanyAPILoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { isEmbedded } = useLayout();
  const { setSnackbar } = useSnackbar();
  const { value: isCreateNewAccountForm, loading } = useFeatureFlag(
    'createNewAccountForm',
    false
  );
  const theme = useTheme();
  const classes = useStyles();
  const { profile, defaultTheme, impersonateUserAlias } = useProfile();

  const userRoles = getValueFromSession('userRoles');
  const showCreateAccountPage: boolean =
    (userRoles?.includes('administrator') || userRoles?.includes('nfc_admin')) ??
    false;

  useEffect(() => {
    fetchCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const fetchCompanyApiKey = React.useCallback(async () => {
    if (selectedCompany) {
      try {
        const nid = selectedCompany.nid;
        setIsCompanyAPILoading(true);
        const data = await getCompanyApiKey(nid);
        setCompanyApiKey(data?.company_api_key ?? null);
        setIsCompanyAPILoading(false);
      } catch (error) {
        setIsCompanyAPILoading(false);
        setCompanyApiKey(null);
      }
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedCompany) {
      fetchCompanyApiKey();
    } else {
      setCompanyApiKey(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany]);

  const fetchCompanies = React.useCallback(async () => {
    const isAdministrator: boolean = userRoles?.includes('administrator');
    const isNFCAdmin: boolean = userRoles?.includes('nfc_admin');
    setIsLoading(true);
    const companiesList = await getCompaniesListing();
    setIsLoading(false);
    if (isAdministrator) {
      setCompanies(companiesList);
    } else if (isNFCAdmin) {
      const filteredCompanies = companiesList?.filter(
        (company) =>
          company.nid === (profile?.field_company_entity?.target_id.toString() ?? '')
      );
      filteredCompanies && setCompanies(filteredCompanies);
    }
  }, [userRoles, profile]);

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    company: '',
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(
        /^[a-zA-Z' -]+$/,
        "First name can only contain letters, single quotes('), hyphens(-), and spaces( )"
      )
      .matches(/^[^ ].*[^ ]$/, 'First name can not start or end with a space')
      .required('First name is required'),
    lastName: Yup.string()
      .matches(
        /^[a-zA-Z' -]+$/,
        "Last name can only contain letters, single quotes('), hyphens(-), and spaces( )"
      )
      .matches(/^[^ ].*[^ ]$/, 'Last name can not start or end with a space')
      .required('Last name is required'),
    email: Yup.string()
      .email('Invalid email')
      .matches(EMAIL_REGEX, 'Invalid email')
      .required('Email is required'),
    company: Yup.string().required('Please select a company'),
  });

  const onSubmit = async (
    values: FormValues,
    { resetForm }: FormikHelpers<FormValues>
  ) => {
    setIsSubmitting(true);
    const user: AddUserProps = {
      cid: selectedCompany?.nid ?? '',
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      api_key: companyApiKey ?? '',
    };
    try {
      const response = await addUserAPI(user);
      const { data, status, error } = response;
      setIsSubmitting(false);
      if (status === 200) {
        setSnackbar({
          open: true,
          message: data,
        });
        setSelectedCompany(null);
        resetForm();
      } else if (error) {
        setSnackbar({
          open: true,
          message: error,
        });
      }
    } catch (error: any) {
      setIsSubmitting(false);
      setSnackbar({
        open: true,
        message:
          error.message ?? 'Sorry something went wrong. Please try again later.',
      });
    }
  };

  if ((!isCreateNewAccountForm && !loading) || !showCreateAccountPage) {
    return <Page404 />;
  }

  return (
    <div className={classes.container}>
      <Helmet>
        {profile?.field_first_name && (
          <meta name="profile:first_name" content={profile.field_first_name} />
        )}
        {profile?.field_last_name && (
          <meta name="profile:last_name" content={profile.field_last_name} />
        )}
      </Helmet>
      <NavigationBar title="Create New Account" colorTheme={defaultTheme} />
      {isLoading ? (
        <LoadingModal loading={isLoading} color={defaultTheme?.iconColor} />
      ) : (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, errors, touched, setFieldError, setFieldTouched }) => (
              <Form>
                <div
                  className={classNames(
                    classes.content,
                    isEmbedded && classes.embeddedContent
                  )}
                >
                  <div className={classes.navigateButtonsContainer}>
                    <NavigateToMyCard iconColor={defaultTheme?.primaryColor} />
                    {impersonateUserAlias && (
                      <NavigateToMyCard
                        showLeftIcon={false}
                        label="Navigate as Myself"
                        navigateAsMyself={true}
                        iconColor={defaultTheme?.primaryColor}
                      />
                    )}
                  </div>
                  <div className={classes.mainContent}>
                    <CreateAccountForm
                      values={values}
                      companies={companies}
                      errors={errors}
                      touched={touched}
                      selectedCompany={selectedCompany}
                      setSelectedCompany={setSelectedCompany}
                      ctaColor={defaultTheme?.secondaryColor}
                    />
                  </div>
                </div>
                <div className={classes.actions}>
                  {Object.keys(errors).length > 0 ? (
                    <Button
                      title={'Create New Account'}
                      ctaColor={defaultTheme?.ctaColor}
                      fontClassName={classes.ctaTitle}
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        let firstErrorField = Object.keys(errors)[0];
                        setFieldTouched(firstErrorField);
                        const errorElement =
                          document.getElementById(firstErrorField);
                        if (errorElement) {
                          errorElement.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                          });
                        }
                      }}
                    />
                  ) : (
                    <Button
                      disabled={isSubmitting}
                      title={'Create New Account'}
                      fontClassName={classes.ctaTitle}
                      type="submit"
                      ctaColor={theme.palette.common.white}
                      bgCtaColor={defaultTheme?.ctaColor}
                    />
                  )}
                </div>
                <LoadingModal
                  loading={isSubmitting || isCompanyAPILoading}
                  color={defaultTheme?.iconColor}
                />
              </Form>
            )}
          </Formik>
        </LocalizationProvider>
      )}
    </div>
  );
};

export default CreateNewAccount;
