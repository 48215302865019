import { currentProfileCachePath } from 'apis/profile';
import { DefaultColors } from 'constants/colors';
import { ProfileProps } from 'types/profile';

export const formatPhoneNumber = (phoneNumber: string) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return phoneNumber;
};

export const formatWorkNumber = (phoneNumber: string) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{2})(\d{2})(\d{4})(\d{4})$/);
  if (match) {
    return '+ ' + match[1] + ' ' + match[2] + ' ' + match[3] + ' ' + match[4];
  }
  return phoneNumber;
};

export const formatNumber = (phoneNum: string) => {
  if (phoneNum && phoneNum.length >= 10) {
    return formatPhoneNumber(phoneNum);
  }
  if (phoneNum && phoneNum.length === 12) {
    return formatWorkNumber(phoneNum);
  }
  return phoneNum;
};

export const translateString = (word: string) => {
  return word.replace(/\s+/g, '+');
};

export const fullAddress = (profile: ProfileProps) => {
  let fullAddress = '';
  let queryAddress = 'https://www.google.com/maps/search/?api=1&query=';
  if (profile.field_address_1) {
    fullAddress += profile.field_address_1 + ', ';
    queryAddress += translateString(profile.field_address_1) + '+';
  }
  if (profile.field_address_line_2) {
    fullAddress += profile.field_address_line_2 + ', ';
    queryAddress += translateString(profile.field_address_line_2) + '+';
  }
  if (profile.field_city) {
    fullAddress += profile.field_city + ', ';
    queryAddress += translateString(profile.field_city) + '+';
  }
  if (profile.field_state) {
    fullAddress += profile.field_state + ' ';
    queryAddress += translateString(profile.field_state) + '+';
  }
  if (profile.field_country) {
    fullAddress += profile.field_country + ' ';
    queryAddress += translateString(profile.field_country);
  }
  if (profile.field_zip) {
    fullAddress += profile.field_zip + ' ';
    queryAddress += translateString(profile.field_zip);
  }

  return {
    fullAddress,
    queryAddress,
  };
};

export const getStreetAddress = ({
  addressLine = '',
  city = '',
  state = '',
  zipCode = '',
}: {
  addressLine: string;
  city: string;
  state: string;
  zipCode: string;
}) => {
  return `${addressLine} \n ${city} ${state} \n ${zipCode}`;
};

export const toCapitalize = (str: string = '') => {
  const lowerCaseStr = str.toLowerCase();
  const arr = lowerCaseStr.split(' ');
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(' ');
};

export const validateLink = (value: string): string => {
  if (value && !value.startsWith('http://') && !value.startsWith('https://')) {
    return `https://${value}`;
  }
  return value;
};
/**
 * Add https as prefix in url string if http:// or http:// is not present as prefix.
 * @param url string
 * @returns string
 */
export const ensureHttps = (url: string): string => {
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  }
  return `https://${url}`;
};
/**
 * Convert url stirng to normal string, which can be used as a key of object.
 * @param url string
 * @returns string
 */
export const convertUrlToKeyString = (url: string) =>
  url.replaceAll('/', '').replaceAll(':', '');

/**
 * Adds https if URL is not consisting http at the starting of the URL
 * @param url string
 * @returns string - a starting with http or https
 */
export const addProtocolInURL = (url: string = '') => {
  return url.startsWith('http') ? url : `https://${url}`;
};

export const hexToRgbA = (hex: string | undefined = DefaultColors.PRIMARY) => {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.3)';
  }
  throw new Error('Bad Hex');
};

export function extractCompanyLogoNumber(input: string): number {
  const regex = /__COMPANY_LOGO_(\d+)__/;
  const match = regex.exec(input);

  if (match) {
    const number = parseInt(match[1], 10);
    if (!isNaN(number)) {
      return number;
    }
  }
  return -1;
}

export function escapeAmpersandInUrl(url: string): string {
  return url ? url.replace(/&/g, '%26') : '';
}
export function removeHtmlEntitiesAndTags(str: string): string {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = str;
  const decodedStr: string = tempElement.textContent || tempElement.innerText || '';
  return decodedStr.replace(/<\/?[^>]+(>|$)/g, '');
}

export const fixBase64 = (base64String: string) => {
  if (!base64String.startsWith('data:image/')) {
    return `data:image/jpeg;base64,${base64String}`;
  } else {
    return base64String;
  }
};

export const getOgKey = (siteKey: string) => {
  let currentProfilePath: string;
  const currentUrl = window.location.href;
  const parsedUrl = new URL(currentUrl);
  if (window.location.pathname === '/reorder') {
    currentProfilePath = window.localStorage.lastVisitedProfileUrl;
  } else {
    currentProfilePath = parsedUrl.pathname;
  }
  return `og-${currentProfilePath}-${siteKey}`;
};

export const vcfXformatting = (input: string): string => {
  return input.replace(/\s+/g, '_').toUpperCase();
};

export const removeHtmlTags = (str: string) => {
  return str.replace(/<\/?[^>]+>/gi, '');
};
