import { baseAPICreator } from './baseAPICreator';
import { encryptAES } from 'utils/encrypt';

interface resetPassProps {
  mail: string;
}

export const resetPass = async ({
  mail,
}: resetPassProps): Promise<string | null> => {
  const api = baseAPICreator({
    baseURL: '/user',
    noAuth: true,
  });
  try {
    const { data } = await api.post('/password', {
      mail,
    });
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

interface userPasswordResetProps {
  uid: string;
  hash: string;
  timestamp: string;
  pass: string;
}

export const userPasswordReset = async ({
  uid,
  hash,
  timestamp,
  pass,
}: userPasswordResetProps): Promise<any> => {
  const api = baseAPICreator({
    baseURL: '/user-pass-reset',
    noAuth: true,
  });
  try {
    const encryptedData = encryptAES(
      JSON.stringify([uid, pass, ['nested']]),
      process.env.REACT_APP_ENCRYPT_KEY || ''
    );
    const { data } = await api.post('/user-reset', {
      hash,
      timestamp,
      data: JSON.parse(encryptedData),
    });
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
