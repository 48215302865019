import axios from 'axios';
import { AnalyticsProps, CampaignEventProps } from 'types/analytics';

export enum CustomButtonType {
  loginButton = 'login-button',
  logOutButton = 'logout-button',
  requestInfo = 'request-info',
  authenticate = 'authenticate',
  authenticateError = 'authenticate-error',
  recoveryPassword = 'recovery-password',
  activateProfile = 'activate-profile',
  resetPassword = 'reset-password',
  privacyPolicy = 'privacy-policy',
  help = 'help',
  share = 'share',
}

export const analyticsUserProfileAPI = async (profile: AnalyticsProps) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_ANALYTICS_MICROSERVICE_URL}/user-profile`,
      profile
    );
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const trackUserProfileAPI = async (profile: AnalyticsProps) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_ANALYTICS_MICROSERVICE_URL}/profile-views`,
      profile
    );
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const trackAddToContactsAPI = async (profile: AnalyticsProps) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_ANALYTICS_MICROSERVICE_URL}/add-to-contact`,
      profile
    );
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const trackPageViewsAPI = async (pageView: AnalyticsProps) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_ANALYTICS_MICROSERVICE_URL}/page-views`,
      pageView
    );
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const trackAddToWalletAPI = async (profile: AnalyticsProps) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_ANALYTICS_MICROSERVICE_URL}/add-to-wallet`,
      profile
    );
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getProfileReportAPI = async (profile: {
  user: string;
  fromDate: string;
  toDate: string;
  dateRange: 'today' | '7D' | '30D' | '12M';
  timeZone: string;
  campaignId?: string;
}) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_ANALYTICS_MICROSERVICE_URL}/report`,
      {
        params: {
          user: profile.user,
          fromDate: profile.fromDate,
          toDate: profile.toDate,
          dateRange: profile.dateRange,
          timeZone: profile.timeZone,
          campaignId: profile.campaignId,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const trackClickToZoomBG = async (profile: AnalyticsProps) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_ANALYTICS_MICROSERVICE_URL}/zoom-bg`,
      profile
    );
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const trackPurchaseOnMetaVerseAPI = async (profile: AnalyticsProps) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_ANALYTICS_MICROSERVICE_URL}/metaverse-order`,
      profile
    );
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const trackCampaignEvent = async (campaignEvent: CampaignEventProps) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_ANALYTICS_MICROSERVICE_URL}/campaign-event`,
      campaignEvent
    );
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const trackClickOfflineQRCode = async (profile: AnalyticsProps) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_ANALYTICS_MICROSERVICE_URL}/offline-qr-code`,
      profile
    );
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const trackClickToDownloadedAsset = async (profile: AnalyticsProps) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_ANALYTICS_MICROSERVICE_URL}/downloaded-asset`,
      profile
    );
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const trackCustomButtonTapped = async (
  profile: AnalyticsProps,
  buttonType: CustomButtonType
) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_ANALYTICS_MICROSERVICE_URL}/track-button-event/${buttonType}`,
      profile
    );
  } catch (error) {
    console.error(error);
    return null;
  }
};
