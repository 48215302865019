import { PropsWithChildren, ReactElement } from 'react';
import { makeStyles } from '@mui/styles';
import { SxProps, Theme, Typography } from '@mui/material';
import classNames from 'classnames';

import { StyledProps } from 'types/styled';
import { useProfile } from 'hooks/useProfile';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    background: theme.palette.common.white,
    borderWidth: 1,
    borderTopStyle: 'solid',
    borderBottomStyle: 'solid',
    borderColor: theme.palette.grey[50],
    margin: '0 -24px',
    width: 'calc(100% + 48px)',

    '@media screen and (min-width: 576px)': {
      width: '100%',
      borderRadius: 16,
      borderStyle: 'solid',
      margin: '0',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // Distribute children to the ends of the container
  },
  fieldDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    marginTop: 24,
    '& .MuiInputBase-root, & .MuiOutlinedInput-root': {
      borderRadius: 8,
    },
  },
  description: {
    '&.MuiTypography-root': {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '24px',
      marginTop: 8,
    },
  },
  tooltip: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  subDescription: {
    '&.MuiTypography-root': {
      marginTop: '8px',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
      fontStyle: 'italic',
    },
  },
  hideDescription: {
    '&.MuiTypography-root': {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
}));

interface FieldGroupProps extends StyledProps {
  title: string;
  description?: string;
  subDescription?: string;
  fieldDetailsClassName?: string;
  helpToolTip?: ReactElement;
  hideDescriptionForMobile?: boolean;
  titleSX?: SxProps;
  descSX?: SxProps;
  textTransform?: 'uppercase' | 'lowercase' | 'capitalize' | 'none';
}

const FieldGroup = ({
  title,
  description,
  subDescription,
  className,
  fieldDetailsClassName,
  helpToolTip,
  children,
  textTransform,
  titleSX,
  descSX,
  hideDescriptionForMobile = false,
}: PropsWithChildren<FieldGroupProps>) => {
  const classes = useStyles();
  const { defaultTheme } = useProfile();
  return (
    <div className={classNames(classes.container, className)}>
      <div className={classes.titleContainer}>
        <Typography
          fontSize={20}
          fontWeight={600}
          lineHeight="24px"
          textTransform={textTransform ?? 'uppercase'}
          color={defaultTheme?.secondaryColor}
          sx={titleSX}
        >
          {title}
        </Typography>
        {helpToolTip && <div className={classes.tooltip}>{helpToolTip}</div>}
      </div>
      {!!description && (
        <Typography
          className={classNames(
            classes.description,
            hideDescriptionForMobile && classes.hideDescription
          )}
          color={defaultTheme?.secondaryColor}
          sx={descSX}
        >
          {description}
        </Typography>
      )}
      {!!subDescription && (
        <Typography
          className={classes.subDescription}
          color={defaultTheme?.secondaryColor}
        >
          {subDescription}
        </Typography>
      )}
      <div className={classNames(classes.fieldDetails, fieldDetailsClassName)}>
        {children}
      </div>
    </div>
  );
};

export default FieldGroup;
