export const convertLinkType = (type: string) => {
  switch (type) {
    case 'cal':
      return 'Calendar';
    case 'photo':
      return 'Photo';
    case 'link':
      return 'Link';
    case 'video':
      return 'Video';
    default:
      return 'Calendar';
  }
};

export const privacyPolicyLink = 'https://www.qcardenterprise.com/privacy-policy';

export const customLinkInNewTab = async (href: string) => {
  const link = document.createElement('a');
  link.href = href;
  link.rel = 'noreferrer';
  link.target = '_blank';
  document.body.appendChild(link);
  await link.click();
  document.body.removeChild(link);
};
