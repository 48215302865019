import { clearAllValuesFromIdb } from 'utils/IndexDB';
import { baseAPICreator } from './baseAPICreator';
import { removeAuthSession, btoa } from 'utils/auth';
import { encryptAES } from 'utils/encrypt';

interface loginProps {
  name: string;
  pass: string;
}

interface loginResponse {
  csrfToken?: string | null;
  logoutToken?: string | null;
  basicAuth?: string | null;
  user: {
    name?: string | null;
    uid?: string | null;
    roles?: string[] | null;
  };
}

export const login = async ({
  name,
  pass,
}: loginProps): Promise<loginResponse | null> => {
  const api = baseAPICreator({
    baseURL: '/user',
    noAuth: true,
  });
  try {
    const { data } = await api.post('/login', {
      name,
      pass,
    });
    const basicAuth = 'Basic ' + btoa(name + ':' + pass);
    return {
      csrfToken: data.csrf_token,
      logoutToken: data.logout_token,
      basicAuth,
      user: data.current_user,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const loginEncrypted = async ({
  name,
  pass,
}: loginProps): Promise<loginResponse | null> => {
  const api = baseAPICreator({
    baseURL: '/qcuser',
    noAuth: true,
  });
  try {
    const encryptedBody = encryptAES(
      JSON.stringify([name, pass, ['nested']]),
      process.env.REACT_APP_ENCRYPT_KEY || ''
    );
    const { data } = await api.post('/login', {
      data: JSON.parse(encryptedBody),
    });
    const { current_user: { name: username = '' } = {} } = data || {};
    const basicAuth = 'Basic ' + btoa(username + ':' + pass);
    return {
      csrfToken: data.csrf_token,
      logoutToken: data.logout_token,
      basicAuth,
      user: data.current_user,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const logout = async (logoutToken: string): Promise<null> => {
  const api = baseAPICreator({
    baseURL: '/user',
    noAuth: true,
    logoutToken,
  });
  try {
    await api.get('/logout');
    await clearAllValuesFromIdb();
    removeAuthSession();
    return null;
  } catch (error) {
    console.log(error);
    await clearAllValuesFromIdb();
    removeAuthSession();
    return null;
  }
};
