import { androidDevices, appleDeivces, windowsBrowsers } from 'constants/userAgents';
import React from 'react';

export default function useDeviceDetect() {
  const [isMobile, setMobile] = React.useState(false);
  const [isApple, setIsApple] = React.useState(false);
  const [isIpad, setIsIpad] = React.useState(false);
  const [isAndroid, setIsAndroid] = React.useState(false);
  const [isWindows, setIsWindows] = React.useState(false);

  React.useEffect(() => {
    const userAgent =
      typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );
    const ipad = Boolean(userAgent.match(/iPad/i));
    const appleRegex = `(${appleDeivces.join('|')})`;
    const androidRegex = `(${androidDevices.join('|')})`;
    const windowsRegex = `(${windowsBrowsers.join('|')})`;
    const isAppleDevice = new RegExp(appleRegex).test(userAgent);
    const isAndroidDevice = new RegExp(androidRegex).test(userAgent);
    const isWindowsDevice = new RegExp(windowsRegex).test(userAgent);
    setIsApple(isAppleDevice);
    setIsAndroid(isAndroidDevice);
    setMobile(mobile);
    setIsIpad(ipad);
    setIsWindows(isWindowsDevice);
  }, []);

  return { isMobile, isApple, isAndroid, isIpad, isWindows };
}
