import axios, { isAxiosError } from 'axios';

import { removeAuthSession } from 'utils/auth';
import { getAuthWithExpire } from 'utils/storage';

interface BaseAPIProps {
  baseURL: string;
  params?: unknown;
  headers?: unknown;
  noAuth?: boolean;
  logoutToken?: string;
}

export const baseAPICreator = ({
  baseURL,
  params,
  headers,
  noAuth = false,
  logoutToken,
}: BaseAPIProps) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API + baseURL,
    params: params || { _format: 'json' },
    headers: headers || {
      Accept: 'appliction/json',
    },
  });

  instance.interceptors.request.use((request) => {
    const storageAuth = getAuthWithExpire();
    const csrfToken = storageAuth?.csrfToken;

    if (!noAuth) {
      const basicAuth = storageAuth?.basicAuth;
      request.headers.Authorization = basicAuth;
    }

    if (logoutToken || csrfToken) {
      request.headers['x-csrf-token'] = logoutToken || csrfToken;
    }

    return request;
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (isAxiosError(error) && error.response) {
        if (error.response?.status < 400) {
          return null;
        }
        if (error.response?.status === 401) {
          removeAuthSession();
          window.location.href = '/login';
        }
        return Promise.reject({
          status: error.response.status,
          error: error.response.data || 'axios error',
        });
      } else {
        return Promise.reject({
          status: 500,
          error: JSON.stringify(error) || 'unknown',
        });
      }
    }
  );

  return instance;
};
