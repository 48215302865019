import { setValueInLocal } from 'utils/storage';
import { baseAPICreator } from './baseAPICreator';
import { currentProfileCachePath, getCurrentUserCachedProfile } from './profile';
import Packages from '../../package.json';

export const getFile = async (id: number): Promise<string | null> => {
  const api = baseAPICreator({
    baseURL: '/file',
  });
  const currentProfile = getCurrentUserCachedProfile();
  try {
    const { data } = await api.get(`/${id}`);
    const currentProfilePath = currentProfileCachePath();
    setValueInLocal(currentProfilePath, {
      ...currentProfile,
      [id]: process.env.REACT_APP_BASE_API + data?.uri[0]?.url,
    });
    return process.env.REACT_APP_BASE_API + data?.uri[0]?.url;
  } catch (error) {
    if (currentProfile[id]) {
      return currentProfile[id];
    }
    console.log(error);
    console.log(Packages.version);
    return null;
  }
};

export const uploadFile = async (body: any): Promise<any> => {
  const api = baseAPICreator({
    baseURL: '/entity',
    params: {
      _format: 'hal_json',
    },
    headers: {
      Accept: 'application/hal+json',
      'Content-Type': 'application/hal+json',
    },
  });
  try {
    const { data } = await api.post('/file', body);
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const uploadNamePronunciationFile = async (body: any): Promise<any> => {
  const api = baseAPICreator({
    baseURL: '/file-audio',
    params: {
      _format: 'json',
    },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    // headers: {
    //   Accept: 'application/hal+json',
    //   'Content-Type': 'application/octet-stream',
    //   'Content-Disposition': `file; filename="${body.file_name}"`,
    // },
  });
  try {
    const { data } = await api.post('/upload', body);
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const uploadUserImage = async (body: any): Promise<any> => {
  const api = baseAPICreator({
    baseURL: '/user-image',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  try {
    const { data } = await api.post('/upload', body);
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
