export const getOrientation = () => {
  if (window.screen.orientation) {
    return window.screen.orientation.type;
  }
  if (
    Math.abs(window.orientation) === 90 &&
    window.matchMedia('(orientation: landscape)').matches
  ) {
    return 'landscape-primary';
  } else {
    return 'portrait-primary';
  }
};

export const isSafari = () => {
  const userAgent = window.navigator.userAgent;
  const isChrome = userAgent.indexOf('Chrome') > -1;
  const isSafari = userAgent.indexOf('Safari') > -1;
  const isMobileApp = userAgent.indexOf('qCardAppiOS') > -1;
  return (isSafari && !isChrome) || isMobileApp;
};

export const isAndroidDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  const isAndroidDevice = /android/i.test(userAgent);
  return isAndroidDevice;
};
