import React, { useEffect, useMemo, useRef } from 'react';
import QRCodeStyling from 'qr-code-styling';
interface QRCodeWithLogoProps {
  size: number;
  value: string;
  logoSrc: string;
  fgColor: string;
}

const QRCodeWithLogo: React.FC<QRCodeWithLogoProps> = ({
  size,
  value,
  logoSrc,
  fgColor,
}) => {
  const qrCodeRef = useRef<HTMLDivElement>(null);
  const isImgTag = logoSrc.includes('<img');

  const getSrcFromImgString = () => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = logoSrc;
    const imgElement = tempDiv.querySelector('img');
    const srcAttribute = imgElement?.getAttribute('src');
    return srcAttribute || '';
  };

  const qrCode = useMemo(
    () =>
      new QRCodeStyling({
        width: size,
        height: size,
        image: isImgTag
          ? getSrcFromImgString()
          : `data:image/svg+xml;base64,${btoa(logoSrc)}`,
        imageOptions: {
          crossOrigin: 'anonymous',
          imageSize: 0.4,
          margin: 5,
        },
      }),
    [logoSrc, size, isImgTag]
  );

  useEffect(() => {
    if (qrCodeRef.current) {
      qrCode.append(qrCodeRef.current);
    }
  }, [qrCode]);

  useEffect(() => {
    qrCode.update({
      data: value,
      dotsOptions: {
        color: fgColor,
      },
    });
  }, [value, qrCode, fgColor]);

  return <div ref={qrCodeRef} />;
};

export default QRCodeWithLogo;
