import { openDB, IDBPDatabase, IDBPTransaction } from 'idb';

interface MyObject {
  [key: string]: any;
}

const handleIdbError = (error: Error) => {
  console.error('IndexedDB Error:', error);
};

const openDbWithUpgrade = async (
  dbName: string,
  version: number,
  upgradeCallback: (
    db: IDBPDatabase<MyObject[]>,
    oldVersion: number,
    newVersion: number | null,
    transaction: IDBPTransaction<MyObject[], string, 'versionchange'>,
    event: IDBVersionChangeEvent
  ) => void
): Promise<IDBPDatabase<MyObject[]> | null> => {
  try {
    const db = await openDB<MyObject[]>(dbName, version, {
      upgrade(db, oldVersion, newVersion, transaction, event) {
        upgradeCallback(db, oldVersion, newVersion, transaction, event);
      },
    });
    return db;
  } catch (error: any) {
    handleIdbError(error);
    return null;
  }
};

const withDb = async <T>(
  callback: (db: IDBPDatabase<MyObject[]>) => Promise<T>
): Promise<T | null> => {
  const dbName = 'qcardDB';
  const version = 1;

  const db = await openDbWithUpgrade(dbName, version, (db) => {
    if (!db.objectStoreNames.contains('qcardStore')) {
      db.createObjectStore('qcardStore');
    }
  });

  if (db) {
    return await callback(db);
  }
  return null;
};

export const getValueFromIdb = async (label: string): Promise<any> => {
  const result = await withDb(async (db) => {
    const tx = db.transaction('qcardStore', 'readonly');
    const store = tx.objectStore('qcardStore');
    const data = await store.get(label);
    return data as any;
  });
  return result;
};

export const setValueInIdb = async (label: string, value: any): Promise<void> => {
  await withDb(async (db) => {
    const tx = db.transaction('qcardStore', 'readwrite');
    const store = tx.objectStore('qcardStore');
    await store.put(value, label);
  });
};

export const removeValueFromIdb = async (label: string): Promise<void> => {
  console.log(label);
  await withDb(async (db) => {
    const tx = db.transaction('qcardStore', 'readwrite');
    const store = tx.objectStore('qcardStore');
    try {
      await store.delete(label);
      // console.log(`Deleted key: ${label}`);
    } catch (error) {
      handleIdbError(error as Error);
    }
  });
};

export const clearAllValuesFromIdb = async (): Promise<void> => {
  await withDb(async (db) => {
    const tx = db.transaction('qcardStore', 'readwrite');
    const store = tx.objectStore('qcardStore');
    try {
      await store.clear();
      console.log(`IDB cleared`);
    } catch (error) {
      handleIdbError(error as Error);
    }
  });
};

export const getAllKeysFromIdb = async (): Promise<string[]> => {
  const keys = await withDb(async (db) => {
    const tx = db.transaction('qcardStore', 'readonly');
    const store = tx.objectStore('qcardStore');
    const allKeys = await store.getAllKeys();
    return allKeys as string[];
  });
  return keys || [];
};
