export const muiTooltip = {
  styleOverrides: {
    tooltip: {
      fontSize: '16px',
      color: '#1F1F1F',
      backgroundColor: '#fff',
      borderRadius: '8px',
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
      border: '2px solid #545454',
      lineHeight: '24px',
      padding: '14px 16px',
      fontWeight: '600',
    },
  },
};
