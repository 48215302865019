import { useState, useEffect } from 'react';

/**
 * Check network online status
 * @returns { isOnline: boolean } network online status
 */
export const useNetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(false);

  useEffect(() => {
    if (!navigator.onLine) {
      setIsOnline(false);
      return;
    }

    (async () => {
      try {
        await fetch('https://www.google.com/', {
          mode: 'no-cors',
          cache: 'no-store',
        });

        setIsOnline(true);
      } catch (error) {
        setIsOnline(false);
      }
    })();

    // Listen for online and offline events
    window.addEventListener('online', () => setIsOnline(true));
    window.addEventListener('offline', () => setIsOnline(false));

    // Cleanup
    return () => {
      window.removeEventListener('online', () => setIsOnline(true));
      window.removeEventListener('offline', () => setIsOnline(false));
    };
  }, []);

  return { isOnline };
};
