import { useCallback, useEffect, useRef, Suspense, lazy } from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom';
import 'react-image-crop/dist/ReactCrop.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ConfigCatProvider, useFeatureFlag } from 'configcat-react';
import { useSearchParams } from 'react-router-dom';
import { ViewportProvider } from 'hooks/useViewport';
import { ThemeProvider } from 'hooks/useTheme';
import { ProfileProvider, useProfile } from 'hooks/useProfile';
import { LayoutProvider } from 'hooks/useLayout';
import {
  getAuthWithExpire,
  getValueFromSession,
  setValueInSession,
} from 'utils/storage';
import { getProfileByURI } from 'apis/profile';
import GlobalStyles from '@mui/material/GlobalStyles';
import { User } from 'types/companyDirectory';
import { SnackbarProvider } from 'hooks/useSnackbar';
import { LoadingModal } from 'components/LoadingModal';
import { checkQCardRNAppDevice } from 'utils/qcardReactNativeHandlers';
import CreateNewAccount from 'pages/CreateNewAccount';

const Home = lazy(() => import('pages/Home'));
const CallAndSMS = lazy(() => import('pages/CallAndSMS'));
const EditQCard = lazy(() => import('pages/EditQCard'));
const ActivationPage = lazy(() => import('pages/ActivationPage'));
const Login = lazy(() => import('pages/Login'));
const Welcome = lazy(() => import('pages/Welcome'));
const GenerateSignature = lazy(() => import('pages/GenerateSignature'));
const UserResetPassword = lazy(() => import('pages/UserResetPassword'));
const DynamicPages = lazy(() => import('pages/DynamicPages'));
const Page404 = lazy(() => import('pages/Page404'));
const Page403 = lazy(() => import('pages/Page403'));
const AnalyticsPage = lazy(() => import('pages/AnalyticsPage'));
const Reorder = lazy(() => import('pages/Reorder'));
const DownloadableAssets = lazy(() => import('pages/DownloadableAssets'));
const AccountActivation = lazy(() => import('pages/AccountActivation'));
const CompanyDirectory = lazy(() => import('pages/CompanyDirectory'));
const ZoomBackground = lazy(() => import('pages/ZoomBackground'));
const storageAuth = getAuthWithExpire();
const currentUser = storageAuth?.user;

interface RoutersProps {
  cacheBusterFeatureFlag?: ({
    isCacheBusterEnabled,
    isCacheBusterLoading,
  }: {
    isCacheBusterEnabled: boolean;
    isCacheBusterLoading: boolean;
  }) => void;
}

export default function Routers(props: RoutersProps) {
  const { cacheBusterFeatureFlag } = props;

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath.startsWith('/c/')) {
      localStorage.setItem('lastVisitedProfileUrl', currentPath);
    }
  }, [window.location.pathname]);

  return (
    <BrowserRouter>
      <ConfigCatProvider sdkKey={process.env.REACT_APP_CONFIGCAT_SDK_KEY as string}>
        <ProfileProvider cacheBusterFeatureFlag={cacheBusterFeatureFlag}>
          <ViewportProvider>
            <ThemeProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <GlobalStyles
                  styles={(theme: any) => ({
                    html: {
                      scrollBehavior: 'smooth',
                    },
                    body: {
                      margin: 0,
                      padding: 0,
                      WebkitFontSmoothing: 'antialiased',
                      MozOsxFontSmoothing: 'grayscale',
                      fontFamily: theme.typography.fontFamily,
                    },
                    '*': {
                      boxSizing: 'border-box',
                    },
                  })}
                />
                <SnackbarProvider>
                  <LayoutProvider>
                    <Suspense fallback={<LoadingModal loading={true} />}>
                      <Routes>
                        <Route path="/:uid?" element={<RedirectWrapper />} />
                        <Route
                          path="/account-welcome/:uid"
                          element={<ActivationPage />}
                        />
                        <Route path="/email" element={<CallAndSMS />} />
                        <Route path="/call" element={<CallAndSMS />} />
                        <Route path="/sms" element={<CallAndSMS />} />
                        <Route path="/login" element={<Login />} />
                        <Route
                          path="/user/reset/:uid/:timestamp/:hash"
                          element={<UserResetPassword />}
                        />
                        <Route path="/pages/:pageType" element={<DynamicPages />} />
                        <Route
                          path="/account-activated"
                          element={<AccountActivation />}
                        />
                        {/* Private Routers */}
                        <Route
                          path="/generate-signature"
                          element={
                            <PrivateRoute>
                              <GenerateSignature />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/editcard"
                          element={
                            <PrivateRoute>
                              <EditQCard />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/reorder"
                          element={
                            <PrivateRoute>
                              <Reorder />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/analytics"
                          element={
                            <PrivateRoute>
                              <AnalyticsPage />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/downloadable-assets"
                          element={
                            <PrivateRoute>
                              <DownloadableAssets />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/zoom-background"
                          element={
                            <PrivateRoute>
                              <ZoomBackground />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/createNewAccount"
                          element={
                            <PrivateRoute>
                              <CreateNewAccount />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/company-directory"
                          element={
                            <PrivateRoute>
                              <CompanyDirectoryWrapper />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/impersonate-user"
                          element={
                            <PrivateRoute>
                              <ImpersonateUserWrapper />
                            </PrivateRoute>
                          }
                        />
                        <Route path="/403" element={<Page403 />} />
                        <Route path="/404" element={<Page404 />} />
                        {/*Don't add new route below Home page*/}
                        <Route path="/c/:username" element={<Home />} />
                        <Route path="*" element={<Page404 />} />
                      </Routes>
                    </Suspense>
                  </LayoutProvider>
                </SnackbarProvider>
              </LocalizationProvider>
            </ThemeProvider>
          </ViewportProvider>
        </ProfileProvider>
      </ConfigCatProvider>
    </BrowserRouter>
  );
}

const RedirectWrapper = ({ uid }: any) => {
  const [searchParams] = useSearchParams();
  let navURL = currentUser?.alias;
  if (searchParams.get('redirect') == 'editcard') {
    navURL = '/editcard';
  }
  const userImpersonation: User = getValueFromSession('userImpersonation');
  return currentUser && !uid && !userImpersonation ? (
    <Navigate to={navURL} />
  ) : (
    <Welcome />
  );
};
const PrivateRoute = ({ noAuthRequired = false, children }: any): any => {
  const { pathname } = useLocation();
  const {
    profile,
    setLoadingProfile,
    setProfile,
    impersonateUserAlias,
    setImpersonateUserAlias,
  } = useProfile();

  const enableFetch = useRef(true);
  const { c, username } = useParams();
  const storageAuth = getAuthWithExpire();
  const csrfToken = storageAuth?.csrfToken;

  const fetchProfile = useCallback(
    async (alias: string) => {
      setLoadingProfile(true);
      const response = await getProfileByURI(alias);
      setProfile(response);
    },
    [setLoadingProfile, setProfile]
  );

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        const userImpersonation = getValueFromSession('userImpersonation');
        if (!userImpersonation && impersonateUserAlias) {
          setImpersonateUserAlias(null);
        }
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (impersonateUserAlias) {
      if (enableFetch.current && username) {
        const path = c && username ? `/${c}/${username}` : `/${username}`;
        fetchProfile(path);
        enableFetch.current = false;
      } else if (enableFetch.current && impersonateUserAlias.view_user) {
        fetchProfile(impersonateUserAlias.view_user);
        enableFetch.current = false;
      }
    } else {
      const userImpersonation: User = getValueFromSession('userImpersonation');
      if (
        userImpersonation &&
        !impersonateUserAlias &&
        userImpersonation.view_user &&
        ((pathname !== userImpersonation.view_user && !pathname.includes('/c/')) ||
          pathname === userImpersonation.view_user)
      ) {
        setImpersonateUserAlias(userImpersonation);
        if (enableFetch.current) {
          fetchProfile(userImpersonation.view_user);
          enableFetch.current = false;
        }
      } else if (currentUser?.alias && profile?.path.alias !== currentUser.alias) {
        if (enableFetch.current) {
          fetchProfile(currentUser.alias);
          enableFetch.current = false;
        }
      }
    }
  }, [
    impersonateUserAlias,
    c,
    username,
    currentUser,
    fetchProfile,
    profile,
    pathname,
  ]);

  if (noAuthRequired) {
    return children;
  }

  if (pathname === '/impersonate-user' && !csrfToken) {
    return <Page404 />;
  }

  if (!csrfToken) {
    setValueInSession('beforelogin', pathname);
    return <Navigate to="/?loginRequired=true" state={{ from: pathname }} />;
  }

  return children;
};

const CompanyDirectoryWrapper = () => <CompanyDirectory />;

const ImpersonateUserWrapper = () => {
  const {
    loading: isImpersonationFeatureLoading,
    value: isImpersonationFeatureEnabled,
  } = useFeatureFlag('isImpersonationFeatureEnabled', false);
  const userImpersonation = getValueFromSession('userImpersonation');
  const userRoles = getValueFromSession('userRoles');
  const { setImpersonateUserAlias } = useProfile();
  const { value: showCompaniesList } = useFeatureFlag('showCompaniesList', false);

  const showImpersonateUser: boolean =
    (userRoles?.includes('administrator') || userRoles?.includes('nfc_admin')) ??
    false;
  const showCompaniesDropdown: boolean =
    showCompaniesList && userRoles.includes('administrator');

  if (
    !userImpersonation &&
    showImpersonateUser &&
    isImpersonationFeatureEnabled &&
    !checkQCardRNAppDevice()
  ) {
    return (
      <CompanyDirectory
        isImpersonateUser={true}
        showCompaniesDropdown={showCompaniesDropdown}
      />
    );
  } else if (
    !userImpersonation &&
    showImpersonateUser &&
    isImpersonationFeatureLoading
  ) {
    return <LoadingModal loading={isImpersonationFeatureLoading} />;
  }
  if (userImpersonation) {
    setImpersonateUserAlias(null);
    setValueInSession('userImpersonation', null);
    window.location.href = window.location.origin + '/impersonate-user';
    return;
  }
  return <Page404 />;
};
