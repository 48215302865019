// muiAccordion Styles Default CSS Update
export const muiAccordion = {
  styleOverrides: {
    root: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      borderTop: '1px solid #CBCBCB',
      '&.Mui-expanded': {
        backgroundColor: 'transparent',
        margin: 0,
      },
      '&:first-of-type': {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
      '&:last-of-type': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
      '&::before': {
        content: 'none',
      },
    },
  },
};

export const muiAccordionSummary = {
  styleOverrides: {
    root: {
      backgroundColor: 'transparent',
      padding: 0,
      '&.Mui-expanded': {
        backgroundColor: 'transparent',
      },
    },
    content: {
      margin: '34px 0',
      '& .MuiTypography-root': {
        lineHeight: '20px',
        fontSize: '16px',
        fontWeight: 600,
      },
      '&.Mui-expanded': {
        margin: '34px 0',
      },
    },
  },
};

export const muiAccordionDetails = {
  styleOverrides: {
    root: {
      backgroundColor: 'white',
      padding: '0 0 32px',
      '& p': {
        lineHeight: '20px',
      },
    },
  },
};
