import { ReorderFieldType } from 'types/reorder';

export const formatReorderResponse = (fields: any[]): ReorderFieldType[] => {
  return fields
    .sort((a: any, b: any) => Number(a.field_order) - Number(b.field_order))
    .map((order: any) => {
      let parsedSubfields;
      try {
        parsedSubfields = JSON.parse(order.field_subfields);
      } catch (e) {
        parsedSubfields = []; // or any default value you want to use in case of invalid JSON
      }

      return {
        id: order.id,
        field_name: order.field_name,
        field_subfields: parsedSubfields,
        is_visible: order.field_is_visible,
        field_order: order.field_order,
      };
    });
};
