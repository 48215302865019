import { getImageBaseUrl } from './image';
import {
  addProtocolInURL,
  extractCompanyLogoNumber,
  formatPhoneNumber,
} from './string';
import { CompanyEntity } from 'hooks/useProfile';
export const linkStyles = `style="
  color: #1F1F1F; 
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-decoration: none;
"`;

export const addressStyles = `style="
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #FFF;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
"`;

export const replaceCompanyName = (
  layout: string,
  companyName: string,
  companyURL: string
): string => {
  if (!companyName) {
    return layout.replace('__COMPANY_LOGO__', '');
  }
  const imgTag = `<img src="${companyName}" alt="Company Logo" style="pointer-events: none; max-width: 110px" />`;
  const companyTag = companyURL
    ? `<a id="preserve-click" href="${addProtocolInURL(
        companyURL
      )}" target="_blank" style="display: block">${imgTag}</a>`
    : imgTag;

  return layout.replace('__COMPANY_LOGO__', companyTag);
};

export const replaceFullName = (layout: string, fullName: string): string => {
  return layout.replace('__FULL_NAME__', fullName);
};

export const replaceQRCode = (layout: string, QRcode: string): string => {
  return layout?.replace(
    '__QR_CODE__',
    QRcode
      ? `<img
        src="https://api.qrserver.com/v1/create-qr-code/?size=230x230&data=${QRcode}"
        style="height: 180px; width: 180px; padding: 10px; background-color: white; margin-top: 100px;"
      />`
      : ''
  );
};

export const replaceRole = (layout: string, role: string): string => {
  return layout.replace('__ROLE__', role);
};

export const replacePronouns = (layout: string, pronouns: string): string => {
  return layout.replace('__PRONOUNS__', pronouns);
};

export const replacePhone = (layout: string, phone: string): string => {
  return layout.replace(
    '__PHONE__',
    `<a id="preserve-click" href="tel:${phone}" ${linkStyles}>${formatPhoneNumber(
      phone
    )}</a>`
  );
};

export const replaceEmail = (layout: string, email: string): string => {
  return layout.replace(
    '__EMAIL__',
    `<a id="preserve-click" href="mailto:${email}" ${linkStyles}>${email}</a>`
  );
};

export const replaceProfilePicture = (
  layout: string,
  profilePicture: string
): string => {
  return profilePicture
    ? layout.replace(
        '__PROFILE_PICTURE__',
        `<img src="${profilePicture}" alt="User Image" />`
      )
    : layout.replace('__PROFILE_PICTURE__', '');
};

export const replaceAddress = (
  layout: string,
  address: string,
  addressURL: string
): string => {
  return layout.replace(
    '__ADDRESS__',
    `<a ${addressStyles} href="${addressURL}">${address}</a>`
  );
};

export const formatPaperViewLayout = async ({
  paperViewLayout,
  companyName,
  companyURL = '',
  fullName,
  role,
  phone,
  email,
  profilePicture,
  address,
  addressURL = '',
  qrCodeURL,
  companyEntity,
  fullAddress = '',
}: {
  paperViewLayout: string;
  companyName: string;
  companyURL?: string;
  fullName: string;
  role: string;
  phone: string;
  email: string;
  profilePicture: string;
  address: string;
  addressURL?: string;
  qrCodeURL: string;
  companyEntity?: CompanyEntity | null;
  fullAddress: string;
}) => {
  const companyLogoNumber = extractCompanyLogoNumber(paperViewLayout);

  const { companyLogos = [] } = companyEntity ?? {};

  const formattedWithCompanyLogo = paperViewLayout.replace(
    `__COMPANY_LOGO_${companyLogoNumber}__`,
    companyLogos
      ? `<img src=${getImageBaseUrl({
          imageUrl: companyLogos[companyLogoNumber - 1]?.field_logo_img,
        })}  />`
      : `<img src=${companyName} style="width:100%" />`
  );

  const formattedCompanyNameLayout = replaceCompanyName(
    formattedWithCompanyLogo,
    companyName,
    companyURL
  );
  const formattedFullNameLayout = replaceFullName(
    formattedCompanyNameLayout,
    fullName
  );
  const formattedRoleLayout = replaceRole(formattedFullNameLayout, role);
  const formattedPhoneLayout = replacePhone(formattedRoleLayout, phone);
  const formattedEmailLayout = replaceEmail(formattedPhoneLayout, email);
  const formattedProfilePictureLayout = replaceProfilePicture(
    formattedEmailLayout,
    profilePicture
  );

  const fullAddressLayoutTrimmed = fullAddress.trim();
  const fullAddressLayoutWithOutComma = fullAddressLayoutTrimmed.endsWith(',')
    ? fullAddressLayoutTrimmed.slice(0, -1)
    : fullAddressLayoutTrimmed;
  const trimmedAddress =
    fullAddressLayoutWithOutComma?.split('\n')?.length >= 3
      ? fullAddressLayoutWithOutComma.split('\n').slice(0, 2).join('\n')
      : fullAddressLayoutWithOutComma;

  const formattedAddressLayout = replaceAddress(
    formattedProfilePictureLayout,
    trimmedAddress,
    addressURL
  );

  const formattedFinal = replaceQRCode(formattedAddressLayout, qrCodeURL);
  return formattedFinal;
};

export const formatBackFlipLayout = async ({
  backFlipLayout,
  profile,
}: {
  backFlipLayout: string;
  profile: any;
}) => {
  let htmlStr = backFlipLayout;
  htmlStr = htmlStr?.replace(
    '__QR_CODE__',
    profile?.metatag?.canonical_url
      ? `<img src="https://api.qrserver.com/v1/create-qr-code/?size=230x230&data=${profile?.metatag?.canonical_url}" style="display: block; height: 230px; width: 230px; padding: 10px;" />`
      : ''
  );
  return htmlStr;
};

export const formatFullProfileHeaderLayout = async ({
  fullProfileHeaderLayout,
  companyName,
  companyURL = '',
  fullName,
  role,
  phone,
  email,
  profilePicture,
  address,
  addressURL = '',
  qrCodeURL,
  companyEntity,
  fullAddress = '',
  pronouns,
}: {
  fullProfileHeaderLayout: string;
  companyName: string;
  companyURL?: string;
  fullName: string;
  role: string;
  phone: string;
  email: string;
  profilePicture: string;
  address: string;
  addressURL?: string;
  qrCodeURL: string;
  companyEntity: CompanyEntity;
  fullAddress: string;
  pronouns: string;
}) => {
  const companyLogoNumber = extractCompanyLogoNumber(fullProfileHeaderLayout);

  const { companyLogos } = companyEntity;

  const formattedWithCompanyLogo = fullProfileHeaderLayout.replace(
    `__COMPANY_LOGO_${companyLogoNumber}__`,
    companyLogos
      ? `<img src=${getImageBaseUrl({
          imageUrl: companyLogos[companyLogoNumber - 1]?.field_logo_img,
        })}  />`
      : `<img src=${companyName} style="width:100%" />`
  );

  const formattedCompanyNameLayout = replaceCompanyName(
    formattedWithCompanyLogo,
    companyName,
    companyURL
  );
  const formattedFullNameLayout = replaceFullName(
    formattedCompanyNameLayout,
    fullName
  );
  const formattedRoleLayout = replaceRole(formattedFullNameLayout, role);

  const formattedPronounsLayout = replacePronouns(formattedRoleLayout, pronouns);

  const formattedPhoneLayout = replacePhone(formattedPronounsLayout, phone);
  const formattedEmailLayout = replaceEmail(formattedPhoneLayout, email);
  const formattedProfilePictureLayout = replaceProfilePicture(
    formattedEmailLayout,
    profilePicture
  );

  const fullAddressLayoutTrimmed = fullAddress.trim();
  const fullAddressLayoutWithOutComma = fullAddressLayoutTrimmed.endsWith(',')
    ? fullAddressLayoutTrimmed.slice(0, -1)
    : fullAddressLayoutTrimmed;
  const trimmedAddress =
    fullAddressLayoutWithOutComma?.split('\n')?.length >= 3
      ? fullAddressLayoutWithOutComma.split('\n').slice(0, 2).join('\n')
      : fullAddressLayoutWithOutComma;

  const formattedAddressLayout = replaceAddress(
    formattedProfilePictureLayout,
    trimmedAddress,
    addressURL
  );

  const formattedFinal = replaceQRCode(formattedAddressLayout, qrCodeURL);
  return formattedFinal;
};
