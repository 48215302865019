import { Typography, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  getAuthWithExpire,
  getValueFromSession,
  setValueInSession,
} from 'utils/storage';
import LeftArrowIcon from 'images/icons/LeftArrow';
import { useProfile } from 'hooks/useProfile';

const BackButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  marginBottom: '30px',
  '& .back--label': {
    marginLeft: 5,
  },
}));

interface NavigateToMyCardProps {
  className?: string;
  label?: string;
  url?: string;
  iconColor?: string;
  showLeftIcon?: boolean;
  navigateAsMyself?: boolean;
}

function NavigateToMyCard({
  className,
  label,
  url,
  iconColor,
  showLeftIcon = true,
  navigateAsMyself = false,
}: NavigateToMyCardProps) {
  const navigate = useNavigate();
  const { impersonateUserAlias, setImpersonateUserAlias } = useProfile();

  const currentUser = getAuthWithExpire()?.user;

  return (
    <BackButtonWrapper
      onClick={() => {
        const userImpersonation = getValueFromSession('userImpersonation');
        if (
          impersonateUserAlias &&
          !!impersonateUserAlias.view_user &&
          navigateAsMyself === false &&
          userImpersonation
        ) {
          if (impersonateUserAlias) {
            window.location.href =
              window.location.origin + impersonateUserAlias.view_user;
          }
        } else {
          if (navigateAsMyself) {
            setImpersonateUserAlias(null);
            setValueInSession('userImpersonation', null);
            window.location.href =
              window.location.origin + currentUser?.alias ?? '/';
          } else {
            navigate(url ?? currentUser?.alias ?? '/');
          }
        }
      }}
    >
      {showLeftIcon && <LeftArrowIcon fill={iconColor} />}
      <span className="back--label">
        <Typography
          fontSize={14}
          fontWeight={700}
          lineHeight="20px"
          className={className}
          color={iconColor}
        >
          {' '}
          {label ? label : 'Back to Profile'}
        </Typography>
      </span>
    </BackButtonWrapper>
  );
}

export default NavigateToMyCard;
