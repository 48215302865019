import {
  addProtocolInURL,
  formatPhoneNumber,
  removeHtmlEntitiesAndTags,
  fullAddress,
  vcfXformatting,
} from './string';

import {
  CustomParagraph,
  ProfileProps,
  RepeatableEmailAddress,
  RepeatablePhoneNumber,
  RepeatableWebsite,
  QLink,
  Language,
  Testimonial,
} from 'types/profile';
import { ContactDetailsTypes } from 'pages/EditQCard/utils/types';
import { ReorderFieldType } from 'types/reorder';
import { useFeatureFlag } from 'configcat-react';
enum PhoneType {
  Home = 'HOME',
  Work = 'WORK',
  Cell = 'CELL',
  Fax = 'FAX',
  Pager = 'PAGER',
  Voice = 'VOICE',
  Video = 'VIDEO',
  Car = 'CAR',
  BBS = 'BBS',
  Modem = 'MODEM',
  ISDN = 'ISDN',
  PCS = 'PCS',
  Preferred = 'PREF',
  Other = 'OTHER',
}

const getPhoneType = (type: ContactDetailsTypes): PhoneType => {
  switch (type) {
    case ContactDetailsTypes.HOME:
      return PhoneType.Home;
    case ContactDetailsTypes.WORK:
      return PhoneType.Work;
    case ContactDetailsTypes.FAX:
      return PhoneType.Fax;
    case ContactDetailsTypes.PAGER:
      return PhoneType.Pager;
    case ContactDetailsTypes.PHONE:
      return PhoneType.Cell;
    default:
      return PhoneType.Other;
  }
};

const e = (value: any) => {
  if (value) {
    if (typeof value !== 'string') {
      value = '' + value;
    }
    return value.replace(/\n/g, '\\n').replace(/,/g, '\\,').replace(/;/g, '\\;');
  }
  return '';
};
function selectById(obj: any, numberToFind?: string): any | undefined {
  for (const key in obj) {
    if (numberToFind && key.includes(numberToFind.toString())) {
      return obj[key];
    }
  }
  return undefined;
}

export const getProfileVCF = ({
  profile,
  photo,
  contacts,
  emails,
  websites,
  isWindows,
  namePronuciationUrl,
  base64Photo,
  isADownload,
  companyName,
  featuredLinks,
  resources,
  customSections,
  languages,
  testimonials,
  detailFieldsOrder,
  noNotes,
  keywordsEnabled,
}: {
  profile: ProfileProps;
  photo?: string | null;
  base64Photo?: string;
  contacts?: RepeatablePhoneNumber[] | null | undefined;
  emails?: RepeatableEmailAddress[] | null | undefined;
  websites?: RepeatableWebsite[] | null | undefined;
  isWindows?: boolean | undefined;
  namePronuciationUrl?: string | null;
  isADownload?: boolean;
  companyName?: string;
  featuredLinks?: QLink[] | null | undefined;
  resources?: QLink[] | null | undefined;
  customSections?: CustomParagraph[] | null | undefined;
  languages?: Language[] | null | undefined;
  testimonials?: Testimonial[] | null | undefined;
  detailFieldsOrder?: ReorderFieldType[] | undefined;
  noNotes?: boolean;
  keywordsEnabled?: boolean;
}): string => {
  // console.log('vcf', {
  //   profile,
  //   photo,
  //   contacts,
  //   emails,
  //   websites,
  //   isWindows,
  //   namePronuciationUrl,
  //   base64Photo,
  //   isADownload,
  //   companyName,
  //   customSections,
  // });
  // check if device is windows or not
  const isVisibleMap: Record<string, boolean | Record<string, boolean>> = {}; // Define the type explicitly

  if (detailFieldsOrder) {
    detailFieldsOrder.forEach((item: any) => {
      if (item.field_subfields && item.field_subfields.length > 0) {
        const isChildVisibleMap: Record<string, boolean> = {};
        item.field_subfields.forEach((subItem: any) => {
          // Use a different name (subItem) for clarity
          isChildVisibleMap[subItem.field_name] = subItem.is_visible;
        });
        isVisibleMap[item.field_name] = isChildVisibleMap;
      } else {
        isVisibleMap[item.field_name] = item.is_visible;
      }
    });
  }

  // const isAboutMeVisible = detailFieldsOrder?.filter(e=>)
  const formatCharset = isWindows ? 'CHARSET=windows-1252' : 'CHARSET=UTF-8';
  let formattedVCardString = '';
  formattedVCardString += 'BEGIN:VCARD\r\n';
  formattedVCardString += 'VERSION:3.0\r\n';
  const encodingPrefix = ';CHARSET=UTF-8';
  let formattedName = '';

  if (!formattedName) {
    formattedName = '';

    [profile.field_last_name, profile.field_first_name].forEach(function (name) {
      if (name) {
        if (formattedName) {
          formattedName += ' ';
        }
      }
      formattedName += name;
    });
  }

  formattedVCardString += 'FN' + encodingPrefix + ':' + e(formattedName) + '\r\n';
  formattedVCardString +=
    'N' +
    encodingPrefix +
    ':' +
    e(profile.field_last_name) +
    ';' +
    e(profile.field_first_name) +
    ';' +
    e('') +
    ';' + // middlename
    e('') +
    ';' + // prefix
    e('') +
    '\r\n'; // suffix
  contacts &&
    contacts.slice(0, 3).forEach((contactDetails) => {
      // remove 0,100 slice to limit how many  remove limiter on 100
      if (contactDetails.field_is_visible) {
        formattedVCardString += `TEL;TYPE=${getPhoneType(
          contactDetails.field_contact_number_type
        )}:${e(formatPhoneNumber(contactDetails.field_contact_number))}\r\n`;
      }
    });
  if (profile.mail) {
    // formattedVCardString += `EMAIL;TYPE=INTERNET:${e(profile.mail)}\r\n`;

    emails?.push({
      field_contact_email: profile.mail,
      field_is_visible: true,
      id: '0',
      parent_id: '0',
      is_visible: true,
      field_comment: '',
    });
  }

  emails &&
    emails.slice(0, 3).forEach((emailDetails) => {
      // remove 0,100 slice to limit how many  remove limiter on 100
      if (emailDetails.field_is_visible) {
        formattedVCardString += `EMAIL;TYPE=INTERNET:${e(
          emailDetails.field_contact_email
        )}\r\n`;
      }
    });

  // websites &&
  //   websites.slice(0, 100).forEach((websiteDetails) => {
  //     if (websiteDetails?.field_contact_websites) {
  //       // remove 0,100 slice to limit how many  remove limiter on 100
  //       if (
  //         websiteDetails?.field_is_visible &&
  //         websiteDetails?.field_contact_websites !== ''
  //       ) {
  //         formattedVCardString += `URL;TYPE=Website:${addProtocolInURL(
  //           e(websiteDetails.field_contact_websites)
  //         )}\r\n`;
  //       }
  //     }
  //   });

  // featuredLinks &&
  //   featuredLinks.slice(0, 100).forEach((featured, index) => {
  //     if (featured?.field_url) {
  //       // remove 0,100 slice to limit how many  remove limiter on 100
  //       formattedVCardString += `item${index + 1}.X-ABlabel:${e(
  //         featured.field_link_label
  //       )}
  //     \r\n`;
  //       formattedVCardString += `item${index + 1}.URL;type=pref:${addProtocolInURL(
  //         e(featured.field_url)
  //       )}\r\n`;
  //     }
  //   });

  // resources &&
  //   resources.slice(0, 100).forEach((resource, index) => {
  //     if (resource?.field_url) {
  //       // remove 0,100 slice to limit how many  remove limiter on 100
  //       formattedVCardString += `item${index + 4}.X-ABlabel:${e(
  //         resource.field_link_label
  //       )}
  //     \r\n`;
  //       formattedVCardString += `item${index + 4}.URL;type=pref:${addProtocolInURL(
  //         e(resource.field_url)
  //       )}\r\n`;
  //     }
  //   });

  // languages &&
  //   languages.slice(0, 100).forEach((languages, index) => {
  //     if (languages?.field_language_user) {
  //       formattedVCardString += `item${index + 4}.X-ABlabel:${e(
  //         languages.field_language_user
  //       )}
  //   \r\n`;
  //       formattedVCardString += `item${index + 4}.X-ABlabel:${e(
  //         languages.field_proficiency
  //       )}
  //   \r\n`;
  //     }
  //   });

  // testimonials &&
  //   testimonials.slice(0, 100).forEach((testimonials, index) => {
  //     if (testimonials?.field_link) {
  //       // remove 0,100 slice to limit how many  remove limiter on 100
  //       formattedVCardString += `item${index + 4}.X-ABlabel:${e(
  //         testimonials.field_testimonial_text
  //       )}
  //     \r\n`;
  //       formattedVCardString += `item${index + 4}.URL;type=pref:${addProtocolInURL(
  //         e(testimonials.field_link)
  //       )}\r\n`;
  //     }
  //   });

  // customSections &&
  //   customSections.forEach((customSectionsDetails) => {
  //     const isCustomSectionVisible = selectById(
  //       isVisibleMap,
  //       customSectionsDetails.id
  //     );
  //     if (
  //       customSectionsDetails.field_custom_section_url &&
  //       customSectionsDetails.field_is_visible
  //     ) {
  //       formattedVCardString += `URL;TYPE=Website:${addProtocolInURL(
  //         e(customSectionsDetails.field_custom_section_url)
  //       )}\r\n`;
  //     }
  //     if (
  //       customSectionsDetails.field_cust_sect_link_1 &&
  //       isCustomSectionVisible.customSection1
  //     ) {
  //       formattedVCardString += `URL;TYPE=Website:${addProtocolInURL(
  //         e(customSectionsDetails.field_cust_sect_link_1)
  //       )}\r\n`;
  //     }
  //     if (
  //       customSectionsDetails.field_cust_sect_link_2 &&
  //       isCustomSectionVisible.customSection2
  //     ) {
  //       formattedVCardString += `URL;TYPE=Website:${addProtocolInURL(
  //         e(customSectionsDetails.field_cust_sect_link_2)
  //       )}\r\n`;
  //     }
  //     if (
  //       customSectionsDetails.field_cust_sect_link_3 &&
  //       isCustomSectionVisible.customSection3
  //     ) {
  //       formattedVCardString += `URL;TYPE=Website:${addProtocolInURL(
  //         e(customSectionsDetails.field_cust_sect_link_3)
  //       )}\r\n`;
  //     }
  //   });

  let formattedAddress = '';

  if (profile) {
    formattedAddress +=
      'ADR' +
      encodingPrefix +
      ';TYPE=Address' +
      ':;;' +
      e(profile ? fullAddress(profile).fullAddress : '' ?? '') +
      '\r\n';
  }

  if (formattedAddress) {
    formattedVCardString += formattedAddress;
  }

  if (profile.field_pronouns === 'Custom' && profile.field_pronouns_custom) {
    formattedVCardString +=
      `NICKNAME;${formatCharset}:` + profile.field_pronouns_custom + '\r\n';
  } else if (profile.field_pronouns && profile.field_pronouns !== 'None') {
    formattedVCardString +=
      `NICKNAME;${formatCharset}:` + profile.field_pronouns + '\r\n';
  }
  if (photo && isADownload === true && base64Photo !== '') {
    let cleanImage = '';
    const imageType = (base64Photo + '').includes('image/jpeg') ? 'JPEG' : 'PNG';
    const base64Prefix = `data:image/${imageType.toLowerCase()};base64,`;
    const base64Cleaned = (base64Photo + '').replace(base64Prefix, '');
    cleanImage = `PHOTO;TYPE=${imageType};ENCODING=BASE64:${base64Cleaned} \r\n`;
    formattedVCardString += cleanImage;
  }
  if (profile.field_position) {
    formattedVCardString += 'TITLE:' + profile.field_position + '\r\n';
  }
  if (profile.field_company || companyName) {
    formattedVCardString +=
      'ORG:' + (companyName ? companyName : profile.field_company) + '\r\n';
  }

  // if (profile.field_about_me && isVisibleMap.details_about_me && noNotes !== true) {
  //   formattedVCardString +=
  //     'NOTE' +
  //     encodingPrefix +
  //     ':' +
  //     e(removeHtmlEntitiesAndTags(profile.field_about_me)) +
  //     '\r\n';
  // }
  const isSocialWebsiteVisible = isVisibleMap.details_social_links;
  const websitesForVCF = [
    {
      type: 'View Complete Professional Profile',
      url: profile.metatag.canonical_url,
    },
    {
      type: 'Linkedin',
      url:
        isSocialWebsiteVisible &&
        typeof isSocialWebsiteVisible === 'object' &&
        isSocialWebsiteVisible.field_linkedin &&
        profile.field_linkedin
          ? profile.field_linkedin.includes('http')
            ? profile.field_linkedin
            : `https://www.linkedin.com/in/${profile.field_linkedin}`
          : null,
    },
    {
      type: 'Facebook',
      url:
        isSocialWebsiteVisible &&
        typeof isSocialWebsiteVisible === 'object' &&
        isSocialWebsiteVisible.field_facebook &&
        profile.field_facebook
          ? profile.field_facebook.includes('http')
            ? profile.field_facebook
            : `https://facebook.com/${profile.field_facebook}`
          : null,
    },
    {
      type: 'Twitter',
      url:
        isSocialWebsiteVisible &&
        typeof isSocialWebsiteVisible === 'object' &&
        isSocialWebsiteVisible.field_twitter &&
        profile.field_twitter
          ? profile.field_twitter.includes('http')
            ? profile.field_twitter
            : `https://twitter.com/${profile.field_twitter}`
          : null,
    },
    {
      type: 'Instagram',
      url:
        isSocialWebsiteVisible &&
        typeof isSocialWebsiteVisible === 'object' &&
        isSocialWebsiteVisible.field_instagram &&
        profile.field_instagram
          ? profile.field_instagram.includes('http')
            ? profile.field_instagram
            : `https://www.instagram.com/${profile.field_instagram}`
          : null,
    },
    {
      type: 'Skype',
      url:
        isSocialWebsiteVisible &&
        typeof isSocialWebsiteVisible === 'object' &&
        isSocialWebsiteVisible.field_skype &&
        profile.field_skype
          ? `${profile.field_skype}`
          : null,
    },
    {
      type: 'Tiktok',
      url:
        isSocialWebsiteVisible &&
        typeof isSocialWebsiteVisible === 'object' &&
        isSocialWebsiteVisible.field_tiktok &&
        profile.field_tiktok
          ? profile.field_tiktok.includes('http')
            ? profile.field_tiktok
            : `https://www.tiktok.com/${profile.field_tiktok}/`
          : null,
    },
    {
      type: 'Whatsapp',
      url:
        isSocialWebsiteVisible &&
        typeof isSocialWebsiteVisible === 'object' &&
        isSocialWebsiteVisible.field_whatsapp &&
        profile.field_whatsapp
          ? profile.field_whatsapp.includes('http')
            ? profile.field_whatsapp
            : `https://api.whatsapp.com/send?phone=${profile.field_whatsapp}`
          : null,
    },
    {
      type: 'Viber',
      url:
        isSocialWebsiteVisible &&
        typeof isSocialWebsiteVisible === 'object' &&
        isSocialWebsiteVisible.field_messanger &&
        profile.field_messanger
          ? `viber://chat?number=${profile.field_messanger.replace(/\+/g, '')}`
          : null,
    },
    {
      type: 'Telegram',
      url:
        isSocialWebsiteVisible &&
        typeof isSocialWebsiteVisible === 'object' &&
        isSocialWebsiteVisible.field_telegram &&
        profile.field_telegram
          ? `https://t.me/${profile.field_telegram}`
          : null,
    },

    // {
    //   type: 'Refresh Data',
    //   url: profile.metatag.canonical_url + '?vcf=true',
    // },
    // {
    //   type: 'Name Pronunciation Audio',
    //   url:
    //     profile.metatag.canonical_url && namePronuciationUrl
    //       ? profile.metatag.canonical_url + '?pronunciation=true'
    //       : null,
    // },
  ];

  websitesForVCF.forEach(({ type, url }) => {
    if (url) {
      formattedVCardString += `URL;TYPE=${type};CHARSET=UTF-8:` + url + '\r\n';
    }
  });
  //KEYWQRDS
  if (keywordsEnabled && profile.field_profile_keywords) {
    formattedVCardString +=
      'NOTE' + encodingPrefix + ':' + profile.field_profile_keywords + '\r\n';
  }
  formattedVCardString += 'UID' + encodingPrefix + ':' + profile.uid + '\r\n';
  formattedVCardString += 'END:VCARD\r\n';

  return formattedVCardString;
};
