export interface CTAButtonProps {
  textColor: string;
  bgColor: string;
}

export const muiButton = {
  defaultProps: {
    sx: {
      '& p': {
        fontSize: 16,
        lineHeight: '20px',
        fontWeight: 700,
      },
    },
  },
};

export const companyCTAButton = {
  defaultProps: {
    sx: {
      '& p': {
        fontSize: 16,
        lineHeight: '20px',
        fontWeight: 700,
      },
    },
  },
  styleOverrides: {},
};
