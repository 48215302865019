import { ColorProps } from 'types/theme';

export const DefaultColors: ColorProps = {
  PRIMARY: '#8544D8',
  PRIMARY_LIGHT: '#F75435',
  PRIMARY_DARK: '#803691',
  BODY_TEXT: '#1F1F1F',
  SECONDARY_TEXT: '#545454',
  SUCCESS: '#00A66E',
  WARNING: '#DD2C00',
  WARNING_LIGHT: '#DD2C001A',
  BLACK: '#000000',
  GREY: '#CBCBCB',
  GREY_100: '#939393',
  GREY_200: '#F0F1F2',
  GREY_300: '#F8F9FA',
  WHITE: '#FFFFFF',
};
