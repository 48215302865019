import { ReactElement, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Typography, styled } from '@mui/material';
import classNames from 'classnames';

import { useViewport } from 'hooks/useViewport';
import { useLayout } from 'hooks/useLayout';

import { ReactComponent as MenuIcon } from 'images/icons/Menu.svg';
import { ReactComponent as ShareIcon } from 'images/icons/Share.svg';
import { useLocation } from 'react-router-dom';
import {
  checkQCardRNAppDevice,
  postMessageToQCardApp,
} from 'utils/qcardReactNativeHandlers';
import ShareModal from './ShareModal';
import { CompanyTheme, useProfile } from 'hooks/useProfile';
import { getAuthWithExpire } from 'utils/storage';
import { DefaultColors } from 'constants/colors';
import { useAnalytics } from 'pages/AnalyticsPage/hooks/useAnalytics';
import { CustomButtonType } from 'apis/analytics';

const useStyles = makeStyles((theme) => ({
  animOnScroll: {
    top: -80,
  },
  animOnScrollActive: {
    top: 0,
  },
  content: {
    width: '100%',
    maxWidth: 1144,
    padding: '12px 24px 10px',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: 24,

    '@media screen and (min-width: 576px)': {
      justifyContent: 'flex-start',
    },
  },
  embeddedContent: {
    paddingTop: 72,
  },
}));

const NavbarHeader = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'headerColor' &&
    prop !== 'animOnScroll' &&
    prop !== 'animOnScrollActive',
})<{ headerColor?: string; animOnScrollActive: boolean; animOnScroll: boolean }>(
  ({ theme, headerColor, animOnScroll, animOnScrollActive }) => ({
    width: '100%',
    top: animOnScrollActive ? 0 : animOnScroll ? -80 : 0,
    position: 'fixed',
    filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1))',
    background: headerColor,
    zIndex: 100,
    transition: 'all 0.5s cubic-bezier(.65,.05,.36,1)',

    '@media screen and (min-width: 576px)': {
      background: theme.palette.common.white,
    },
  })
);

const NavbarTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'textColor',
})<{ textColor?: string }>(({ theme, textColor }) => ({
  fontSize: 14,
  lineHeight: '28px',
  fontWeight: 700,
  color: theme.palette.common.white,
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'center',
  width: '100%',
  '@media screen and (min-width: 576px)': {
    fontSize: 22,
    lineHeight: '40px',
    color: textColor,
    textTransform: 'none',
    textAlign: 'start',
  },
}));

interface NavigationBarProps {
  title: string;
  animOnScrollActive?: boolean;
  animOnScroll?: boolean;
  showShareIcon?: boolean;
  colorTheme?: CompanyTheme;
}

export const NavigationBar = ({
  title,
  animOnScroll = false,
  animOnScrollActive = false,
  showShareIcon = false,
  colorTheme,
}: NavigationBarProps): ReactElement => {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const { isEmbedded, setOpenMenu, setShowLoginModal } = useLayout();
  const { width } = useViewport();
  const isMobile = width < 576;
  const { profile } = useProfile();
  const [isShareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const { trackCustomButtonTappedEvent } = useAnalytics(null);

  const currentProfileLink = window.location.origin + location.pathname;
  const handleOpenMenu = () => {
    setOpenMenu(true);
  };

  const handleShareProfileLink = async () => {
    if (checkQCardRNAppDevice()) {
      postMessageToQCardApp('navigationProfileShare', {
        url: currentProfileLink,
      });
    } else {
      try {
        if (navigator.share) {
          await navigator.share({
            url: currentProfileLink,
            title: `Professional Profile of ${profile?.metatag?.title}`,
            text: `Hi, click the link to view the professional profile of ${profile?.metatag?.title}.`,
          });
        } else {
          console.log('navigator.share is not supported');
        }
      } catch (error: any) {
        if (error.name === 'AbortError') {
          console.log('The sharing operation was aborted.');
        } else {
          console.error('Error sharing:', error.message);
        }
      }
    }
  };
  const currentUser = getAuthWithExpire()?.user;

  const handleLoginModal = () => {
    trackCustomButtonTappedEvent(CustomButtonType.loginButton, {
      buttonSource: 'Side Menu',
    });
    setShowLoginModal(true);
  };

  return (
    <NavbarHeader
      animOnScrollActive={animOnScrollActive}
      animOnScroll={animOnScroll}
      headerColor={colorTheme?.headerBgColor}
    >
      <div
        className={classNames(
          classes.content,
          isEmbedded && classes.embeddedContent
        )}
      >
        {currentUser ? (
          <MenuIcon
            cursor="pointer"
            width={24}
            height={24}
            fill={isMobile ? theme.palette.common.white : colorTheme?.ctaColor}
            onClick={handleOpenMenu}
            style={{ flex: '0 0 22px' }}
          />
        ) : (
          <div onClick={handleLoginModal} style={{ cursor: 'pointer' }}>
            <Typography
              color={colorTheme?.headerBgColor}
              fontSize={14}
              fontWeight={600}
              lineHeight="24px"
            >
              LOGIN
            </Typography>
          </div>
        )}
        <NavbarTitle
          textColor={colorTheme?.primaryColor}
          onClick={() => window.scrollTo(0, 0)}
        >
          {title}
        </NavbarTitle>
        {isMobile && showShareIcon && (
          <ShareIcon
            width={24}
            height={24}
            onClick={() => setShareModalOpen(true)}
          />
        )}
        <ShareModal
          handleClose={() => setShareModalOpen(false)}
          code={currentProfileLink}
          open={isShareModalOpen}
          title="Professional Profile"
          handleShare={handleShareProfileLink}
          vcf={true}
        />
      </div>
    </NavbarHeader>
  );
};
