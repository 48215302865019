import {
  ProfileProps,
  RepeatableEmailAddress,
  RepeatablePhoneNumber,
  RepeatableWebsite,
  CustomParagraph,
  QLink,
} from 'types/profile';
import { fullAddress } from 'utils/string';
import { contactDetailsList } from 'pages/EditQCard/utils/types';
import { ReorderFieldType, ReorderSubFieldType } from 'types/reorder';

export const orderSocials = (profile: ProfileProps, extended = false) => {
  const result = [];
  if (profile?.field_linkedin) {
    result.push({
      field_name: 'field_linkedin',
      icon: 'LinkedIn',
      title: 'LinkedIn',
      is_visible: true,
    });
  }
  if (profile?.field_facebook) {
    result.push({
      field_name: 'field_facebook',
      icon: 'Facebook',
      title: 'Facebook',
      is_visible: true,
    });
  }
  if (profile?.field_twitter) {
    result.push({
      field_name: 'field_twitter',
      icon: 'Twitter',
      title: 'Twitter',
      is_visible: true,
    });
  }
  if (profile?.field_instagram) {
    result.push({
      field_name: 'field_instagram',
      icon: 'Instagram',
      title: 'Instagram',
      is_visible: true,
    });
  }
  if (profile?.field_tiktok) {
    result.push({
      field_name: 'field_tiktok',
      icon: 'TikTok',
      title: 'TikTok',
      is_visible: true,
    });
  }
  if (profile?.field_skype && extended) {
    result.push({
      field_name: 'field_skype',
      icon: 'Skype',
      title: 'Skype',
      is_visible: true,
    });
  }
  if (profile?.field_whatsapp && extended) {
    result.push({
      field_name: 'field_whatsapp',
      icon: 'Whatsapp',
      title: 'Whatsapp',
      is_visible: true,
    });
  }
  if (profile?.field_telegram && extended) {
    result.push({
      field_name: 'field_telegram',
      icon: 'Telegram',
      title: 'Telegram',
      is_visible: true,
    });
  }
  if (profile?.field_messanger && extended) {
    result.push({
      field_name: 'field_messanger',
      icon: 'Viber',
      title: 'Viber',
      is_visible: true,
    });
  }
  return result;
};

export const orderContactDetails = (
  profile: ProfileProps,
  repeatableEmailAddress: RepeatableEmailAddress[] | null,
  repeatablePhoneNumbers: RepeatablePhoneNumber[] | null
) => {
  const result = [];
  // console.log(profile)
  if (fullAddress(profile).fullAddress) {
    result.push({
      field_name: 'Location',
      icon: 'Location',
      title:
        (profile.field_address_1 || '') +
        '::' +
        (profile.field_address_line_2 || '') +
        '::' +
        (profile.field_city || '') +
        (profile.field_state ? `, ${profile.field_state}` : '') +
        ' ' +
        (profile.field_zip || ''),
      is_visible: true,
    });
  } else {
    result.push({
      field_name: 'Location',
      icon: 'Location',
      is_visible: true,
    });
  }
  if (profile?.mail) {
    result.push({
      field_name: 'Inbox',
      icon: 'Inbox',
      title: profile?.mail,
      is_visible: true,
    });
  }
  if (repeatableEmailAddress && repeatableEmailAddress.length > 0) {
    repeatableEmailAddress.forEach((item) => {
      result.push({
        field_name: 'Inbox:' + item.id,
        icon: 'Inbox',
        title: item.field_contact_email,
        is_visible: item.field_is_visible,
      });
    });
  }
  if (repeatablePhoneNumbers && repeatablePhoneNumbers.length > 0) {
    repeatablePhoneNumbers.forEach((item) => {
      if (item.field_contact_number_type) {
        result.push({
          field_name: 'Phone:' + item.id,
          icon: contactDetailsList[item.field_contact_number_type].icon,
          title: item.field_contact_number,
          is_visible: item.field_is_visible,
        });
      }
    });
  }
  return result;
};

export const orderWebsites = (repeatableWebsites: RepeatableWebsite[] | null) => {
  let result: any[] = [];
  if (repeatableWebsites && repeatableWebsites.length > 0) {
    repeatableWebsites.forEach((item) => {
      if (item.field_contact_websites) {
        const entry = {
          field_name: 'Internet:' + item.id,
          icon: 'Internet',
          title: item.field_contact_websites,
          is_visible: item.field_is_visible,
        };

        result.push(entry);
      }
    });
  }
  return result;
};

export const orderCustomSectionLinks = (customSection: CustomParagraph) => {
  const result = [];
  if (customSection.field_cust_sect_link_1) {
    result.push({
      field_name: 'customSection1',
      title: customSection.field_cust_sect_link_1,
      comment: customSection.field_cust_sec_link1_comment,
      feature_tag: customSection.field_cust_sec_link1_tag,
      is_visible: true,
    });
  }
  if (customSection.field_cust_sect_link_2) {
    result.push({
      field_name: 'customSection2',
      title: customSection.field_cust_sect_link_2,
      comment: customSection.field_cust_sect_link2_comment,
      feature_tag: customSection.field_cust_sec_link2_tag,
      is_visible: true,
    });
  }
  if (customSection.field_cust_sect_link_3) {
    result.push({
      field_name: 'customSection3',
      title: customSection.field_cust_sect_link_3,
      comment: customSection.field_cust_sect_link3_comment,
      feature_tag: customSection.field_cust_sec_link3_tag,
      is_visible: true,
    });
  }
  return result;
};

export const syncOrders = (
  defaultOrders: ReorderFieldType[],
  savedOrders: ReorderFieldType[]
) => {
  const defaultOrdersTemp = [...defaultOrders];
  const result: ReorderFieldType[] = [];
  savedOrders.forEach((order: ReorderFieldType) => {
    const index = defaultOrdersTemp.findIndex(
      (item) => item.field_name === order.field_name
    );
    if (index > -1) {
      const field = defaultOrdersTemp.splice(index, 1)[0];
      const defaultSubFields = field.field_subfields;
      let subFields: ReorderSubFieldType[] | undefined = [];
      if (order.field_subfields && defaultSubFields && defaultSubFields.length) {
        order.field_subfields.forEach((subOrder: ReorderSubFieldType) => {
          const subIndex = defaultSubFields.findIndex(
            (item) => item.field_name === subOrder.field_name
          );
          if (subIndex > -1) {
            const subField = defaultSubFields.splice(subIndex, 1)[0];
            subField.is_visible =
              order.field_name.includes('contact_details') ||
              order.field_name.includes('websites')
                ? subField.is_visible
                : subOrder.is_visible;
            if (order.field_name.includes('contact_details')) {
              order.field_subfields?.map((subFieldItem, index) => {
                if (
                  (subFieldItem.field_name.includes('Inbox') ||
                    subFieldItem.field_name.includes('Location')) &&
                  subFieldItem.field_name === subField.field_name
                ) {
                  subField.is_visible = subFieldItem.is_visible;
                }
              });
            }
            subFields?.push(subField);
          }
        });
        // console.log('subFields', subFields, defaultSubFields, [...subFields, ...defaultSubFields])
        subFields = [...subFields, ...defaultSubFields];
      } else {
        subFields = defaultSubFields;
      }
      field.field_subfields = subFields;
      field.id = order.id;
      field.is_visible =
        order.field_name === 'header_featured_links'
          ? true
          : order.field_name.includes(`details_custom_sections`)
          ? field.is_visible
          : subFields
          ? subFields?.some((item) => item.is_visible)
          : order.is_visible;
      result.push(field);
    }
  });
  // console.log('result', result, 'defaultOrdersTemp', defaultOrdersTemp)
  if (defaultOrdersTemp.length > 0) {
    const newlyCreatedCustoms = defaultOrdersTemp.filter((item) =>
      item.field_name.includes('details_custom_sections')
    );
    const sectionWithoutNewCustoms = defaultOrdersTemp.filter(
      (item) => !item.field_name.includes('details_custom_sections')
    );
    const lastCustomSectionIndex = result
      .slice()
      .reverse()
      .findIndex((obj: any) => obj.field_name.includes('details_custom_sections'));

    if (lastCustomSectionIndex > -1) {
      result.splice(
        result.length - lastCustomSectionIndex,
        0,
        ...newlyCreatedCustoms
      );

      return [...result, ...sectionWithoutNewCustoms];
    }
  }

  return [...result, ...defaultOrdersTemp];
};

export const formatOrderSubmitBody = (fields: ReorderFieldType[]) =>
  fields.map((field, index) => {
    const result: any = {
      field_name: field.field_name,
      field_subfields: JSON.stringify(
        field.field_subfields
          ? field.field_subfields.map((item) => ({
              field_name: item.field_name,
              is_visible: item.is_visible,
            }))
          : []
      ),
      is_visible: field.field_subfields?.length
        ? field.field_subfields.some((item) => item.is_visible)
        : field.is_visible,
      order: index,
    };
    if (field.field_name.includes('details_custom_sections')) {
      result.is_visible = field.is_visible;
      if (!field.is_visible) {
        result.field_subfields = JSON.stringify(
          field.field_subfields
            ? field.field_subfields.map((item) => ({
                field_name: item.field_name,
                is_visible: false,
              }))
            : []
        );
      }
    }
    if (field.id) {
      result.target_id = Number(field.id);
    }
    return result;
  });

export const orderFeaturedLinks = (
  featuredLinks: QLink[],
  fieldOrders: ReorderFieldType[]
) => {
  const addToContactsSubfield = {
    field_name: 'header_add_to_contacts',
    title: 'Add To Contacts',
    is_visible: true,
  };

  let field_subfields = fieldOrders?.find(
    (item) => item.field_name === 'header_featured_links'
  )?.field_subfields ?? [addToContactsSubfield];

  if (fieldOrders.length <= 1 && featuredLinks.length > 0) {
    featuredLinks?.map((link: QLink) => {
      field_subfields.push({
        field_name: 'header_featured_links:' + link.id,
        title: link.field_link_label,
        is_visible: true,
      });
    });
    return field_subfields;
  } else {
    field_subfields = field_subfields!.map((subField: ReorderSubFieldType) => {
      const featuredLink = featuredLinks.find(
        (item: QLink) => subField.field_name === 'header_featured_links:' + item.id
      );
      if (featuredLink) {
        subField.title = featuredLink.field_link_label;
        subField.field_name = 'header_featured_links:' + featuredLink.id;
      } else if (subField.field_name === 'header_add_to_contacts') {
        subField = addToContactsSubfield;
      }
      return subField;
    });
  }

  const addToContactsIndex = field_subfields?.findIndex(
    (subField: any) => subField.field_name === addToContactsSubfield.field_name
  );

  if (addToContactsIndex === -1) {
    field_subfields = [addToContactsSubfield, ...field_subfields];
  }

  return field_subfields;
};
