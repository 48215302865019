import CryptoJS from 'crypto-js';

const JsonFormatter = {
  stringify: function (cipherParams: any) {
    // create json object with ciphertext
    const jsonObj: any = {
      ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64),
    };
    // optionally add iv or salt
    if (cipherParams.iv) {
      jsonObj.iv = cipherParams.iv.toString();
    }
    if (cipherParams.salt) {
      jsonObj.s = cipherParams.salt.toString();
    }
    // stringify json object
    return JSON.stringify(jsonObj);
  },
  parse: function (jsonStr: any) {
    // parse json string
    var jsonObj = JSON.parse(jsonStr);
    // extract ciphertext from json object, and create cipher params object
    var cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(jsonObj.ct),
    });
    // optionally extract iv or salt
    if (jsonObj.iv) {
      cipherParams.iv = CryptoJS.enc.Hex.parse(jsonObj.iv);
    }
    if (jsonObj.s) {
      cipherParams.salt = CryptoJS.enc.Hex.parse(jsonObj.s);
    }
    return cipherParams;
  },
};

export const encryptAES = (value: string, key: string) =>
  CryptoJS.AES.encrypt(value, key, {
    format: JsonFormatter,
  }).toString();

export const decryptedAES = (encryptedValue: any, key: string) =>
  CryptoJS.AES.decrypt(encryptedValue, key, {
    format: JsonFormatter,
  }).toString(CryptoJS.enc.Utf8);
