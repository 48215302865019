import { ReactElement } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import Modal from 'react-modal';
import ReactLoading from 'react-loading';
import { DefaultColors } from 'constants/colors';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    margin: 'none',
    border: 'none',
    padding: 'none',
    background: 'transparent',
    overflow: 'hidden',
  },
};

interface LoadingModalProps {
  loading: boolean;
  color?: string;
}

export const LoadingModal = ({
  loading,
  color = DefaultColors.PRIMARY,
}: LoadingModalProps): ReactElement => {
  const classes = useStyles();

  return (
    <Modal
      isOpen={loading}
      style={customStyles}
      contentLabel="Loading Screen"
      ariaHideApp={false}
    >
      <div className={classNames(classes.container)}>
        <ReactLoading type="spokes" color={color} />
      </div>
    </Modal>
  );
};
