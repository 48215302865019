import styled from '@emotion/styled';
import { Alert, AlertColor, Snackbar, Theme, useTheme } from '@mui/material';
import { ReactNode, createContext, useContext, useState } from 'react';
import { ReactComponent as CloseIcon } from 'images/icons/CloseSmall.svg';

interface SnackbarState {
  open: boolean;
  message: ReactNode;
  severity?: AlertColor | undefined;
}

type SnackbarContextType = {
  setSnackbar: (values: SnackbarState) => void;
};

const StyledAction = styled('div')(({ theme }: { theme: Theme }) => ({
  position: 'relative',
  '&::before': {
    content: '"|"',
    position: 'absolute',
    right: '24px',
    fontSize: '28px',
    lineHeight: '20px',
  },
  '& svg': {
    display: 'block',
    fill: theme.palette.common.white,
  },
}));

const StyledSnackbar = styled(Snackbar)(({ theme }: { theme: Theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: theme.spacing(2),
    '& .MuiSnackbarContent-message': {
      maxWidth: '90%',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
}));

export const SnackbarContext = createContext<SnackbarContextType>({
  setSnackbar: () => {},
});

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();

  const [snackbarState, setSnackbarState] = useState<SnackbarState>({
    open: false,
    message: '',
    severity: undefined,
  });

  const setSnackbar = (values: SnackbarState) => {
    setSnackbarState(values);
  };

  const closeSnackbar = () => {
    setSnackbarState({
      open: false,
      message: '',
      severity: undefined,
    });
  };

  const action = (
    <StyledAction theme={theme}>
      <CloseIcon cursor="pointer" onClick={closeSnackbar} />
    </StyledAction>
  );

  return (
    <SnackbarContext.Provider value={{ setSnackbar }}>
      {children}
      <StyledSnackbar
        open={snackbarState.open}
        message={!snackbarState.severity ? snackbarState.message : undefined}
        onClose={closeSnackbar}
        autoHideDuration={6000}
        action={action}
        theme={theme}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {snackbarState.severity && (
          <Alert severity={snackbarState.severity} variant="filled">
            {snackbarState.message}
          </Alert>
        )}
      </StyledSnackbar>
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const { setSnackbar } = useContext(SnackbarContext);

  return { setSnackbar };
};
