import { PropsWithChildren, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from 'react-modal';
import { makeStyles } from '@mui/styles';
import ShareIcon from 'images/icons/ShareIcon';
import LinkIcon from 'images/icons/LinkIcon';
import EmailIcon from 'images/icons/EmailIcon';
import TextIcon from 'images/icons/TextIcon';
import AirDropIcon from 'images/icons/Airdrop';
import NearByIcon from 'images/icons/NearBy';
import { QRCode } from './QRCode';
import { ReactComponent as CloseIcon } from 'images/icons/Close.svg';
import useDeviceDetect from 'hooks/useDeviceDetect';
import {
  handleShareEmail,
  handleShareText,
  useHandleShareLink,
  useHandleDownloadLink,
} from 'utils/shareOptions';
import { useProfile } from 'hooks/useProfile';
import { checkQCardRNAppDevice } from 'utils/qcardReactNativeHandlers';
import { formatPhoneNumber } from 'utils/string';
import { useAnalytics } from 'pages/AnalyticsPage/hooks/useAnalytics';
import { CustomButtonType } from 'apis/analytics';
import { ShareType } from 'types/analytics';

const useStyles = makeStyles((theme) => ({
  modalWrapper: {
    position: 'relative',
    border: 'none',
    outline: 'none',
    padding: theme.spacing(4),
    borderRadius: 16,
    width: '100%',
    maxWidth: 499,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.15)',
    maxHeight: '90vh',
    overflow: 'auto',
    '@media screen and (max-width: 768px)': {
      maxWidth: 575,
      borderRadius: 0,
      minHeight: '100%',
    },
  },
  root: {
    '& .heading': {
      textAlign: 'center',
      margin: theme.spacing(10, 0, 0),
      '& p': {
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 400,
      },
      '& h3': {
        fontSize: '30px',
        lineHeight: '40px',
        fontWeight: 500,
        wordBreak: 'break-word',
        whiteSpace: 'break-spaces',
      },
      '& h5': {
        fontSize: '20px',
        lineHeight: '30px',
        fontWeight: 500,
        wordBreak: 'break-word',
        whiteSpace: 'break-spaces',
      },
    },
  },
  IconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    columnGap: '16px',
    '& .icons': {
      minWidth: '72px',
      textAlign: 'center',
      textDecoration: 'none',
      '& p': {
        margin: 0,
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 600,
      },
    },
  },
  overlayClass: {
    position: 'fixed',
    inset: '0px',
    backgroundColor: 'rgb(0 0 0 / 50%)',
    overflow: 'auto',
  },
  shareWrapper: {
    display: 'flex',
    justifyContent: 'center',
    columnGap: '8px',
    alignItems: 'center',
    margin: theme.spacing(8, 0, 6),
    '& h6': {
      fontSize: '16px',
      lineHeight: '18px',
      fontWeight: 600,
    },
    '& p': {
      margin: 0,
      fontSize: '12px',
      lineHeight: '18px',
      display: 'flex',
      '& span': {
        margin: theme.spacing(0, 1),
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
  qrCodeWrapper: {
    textAlign: 'center',
    margin: theme.spacing(4, 0),
  },
  qrCode: {
    zIndex: 'auto',
    borderRadius: 0,
    boxShadow: 'none',
    padding: theme.spacing(6),
    '@media screen and (min-width: 576px)': {
      borderRadius: 16,
    },
  },
  closeIcon: {
    position: 'absolute',
    right: 16,
    top: 16,
  },
}));

interface ShareModalProps {
  handleClose: () => void;
  handleOpen?: () => void;
  open: boolean;
  code: string;
  logo?: string;
  isShareable?: boolean;
  handleShare?: () => void;
  title?: string;
  shareLabel?: string;
  downloadableData?: string;
  vcf?: boolean;
}

export default function ShareModal({
  open,
  code,
  children,
  isShareable = true,
  handleClose,
  handleShare,
  logo,
  title = 'Profile',
  shareLabel = '',
  downloadableData,
  vcf,
}: PropsWithChildren<ShareModalProps>) {
  const { isAndroid, isApple, isWindows } = useDeviceDetect();
  const { profile, companyTheme } = useProfile();
  const classes = useStyles({ companyTheme });
  const isNavigatorShareAvailable = navigator.share !== undefined;
  const profileUrl = `${window.location.origin}${profile?.metatag?.canonical_url}?vcf=true`;
  const isBusinessCard = title === 'Digital Business Card';
  const isOfflineCard = title === 'Downloadable Contact Card (Works Offline)';
  const { trackCustomButtonTappedEvent } = useAnalytics(null);
  const handleShareLink = useHandleShareLink(
    profile,
    isBusinessCard ? profileUrl : code,
    isAndroid
  );

  useEffect(() => {
    if (open) {
      trackShareEvent('Open');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleDownloadLink = useHandleDownloadLink(
    profile,
    isBusinessCard ? profileUrl : downloadableData + '',
    isAndroid
  );

  const displayTitle = () => {
    if (!title) {
      return '';
    }

    if (title === 'Social Platform') {
      return shareLabel;
    } else if (title.includes('Phone Number')) {
      let text = title;
      if (title.includes(':')) {
        const label = title.slice(0, title.indexOf(':') + 1);
        const phoneNumber = title.slice(title.indexOf(':') + 1);
        text = `${label}\n${formatPhoneNumber(phoneNumber).trim()}`;
      }
      return `${shareLabel[0].toUpperCase()}${shareLabel
        .slice(1)
        .toLowerCase()}\n${text}`;
    } else if (title.includes(':')) {
      const label = title.slice(0, title.indexOf(':') + 1);
      let value = title.slice(title.indexOf(':') + 1);
      return `${label}\n${value.trim()}`;
    } else {
      return title.trim();
    }
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [open]);

  const trackShareEvent = (shareType: ShareType) => {
    trackCustomButtonTappedEvent(CustomButtonType.share, {
      shareType,
      title: title?.split(': ')[0],
    });
  };

  return (
    <Modal
      isOpen={open}
      onRequestClose={handleClose}
      className={classes.modalWrapper}
      overlayClassName={classes.overlayClass}
      style={{
        content: { backgroundColor: companyTheme?.bgProfileColor as string },
      }}
      ariaHideApp={false}
    >
      <CloseIcon
        cursor="pointer"
        className={classes.closeIcon}
        onClick={(event) => {
          event.stopPropagation();
          handleClose();
        }}
      />
      <Box className={classes.root}>
        <Box className="heading">
          <Typography color={companyTheme?.secondaryColor}>Share my</Typography>
          <Typography
            variant={shareLabel === 'Email' ? 'h5' : 'h3'}
            color={companyTheme?.primaryColor}
          >
            {displayTitle()}
          </Typography>
        </Box>
        <Box className={classes.qrCodeWrapper}>
          {code && (
            <QRCode
              code={code}
              logo={logo}
              size={248}
              qrLogoColor={companyTheme?.iconColor}
              qrCodeColor={companyTheme?.qrcodeColor}
              className={classes.qrCode}
            >
              {children}
            </QRCode>
          )}
        </Box>
        {profile && (
          <Box className={classes.IconWrapper}>
            <div
              className="icons"
              onClick={() => {
                trackCustomButtonTappedEvent(CustomButtonType.share, {
                  shareType: 'Link',
                  title: title?.split(': ')[0],
                });
                isOfflineCard ? handleDownloadLink() : handleShareLink();
              }}
            >
              <LinkIcon fill={companyTheme?.ctaColor} />
              <Typography color={companyTheme?.secondaryColor}>Link</Typography>
            </div>
            <div
              className="icons"
              onClick={() => {
                trackShareEvent('Email');
                handleShareEmail(
                  profile,
                  title,
                  isBusinessCard ? profileUrl : code,
                  shareLabel,
                  vcf
                );
              }}
            >
              <EmailIcon fill={companyTheme?.ctaColor} />
              <Typography color={companyTheme?.secondaryColor}>Email</Typography>
            </div>
            <div
              className="icons"
              onClick={() => {
                trackShareEvent('Text');
                handleShareText(
                  profile,
                  0,
                  title,
                  isBusinessCard ? profileUrl : code,
                  shareLabel,
                  isApple,
                  vcf
                );
              }}
            >
              <TextIcon fill={companyTheme?.ctaColor} />
              <Typography color={companyTheme?.secondaryColor}>Text</Typography>
            </div>
            {/* The commented code should be uncommented when NFC is implemented */}
            {/* <div className="icons">
            <NFCIcon />
            <p>NFC</p>
          </div> */}
          </Box>
        )}
        {isApple && (isNavigatorShareAvailable || checkQCardRNAppDevice()) && (
          <Box className={classes.shareWrapper} onClick={handleShare}>
            <div>
              <ShareIcon fill={companyTheme?.ctaColor} />
            </div>
            <div>
              <Typography variant="h6" color={companyTheme?.secondaryColor}>
                More share options
              </Typography>
              <p>
                (including
                <span>
                  <AirDropIcon fill={companyTheme?.ctaColor} />
                </span>
                AirDrop)
              </p>
            </div>
          </Box>
        )}
        {isAndroid &&
          !isWindows &&
          (isNavigatorShareAvailable || checkQCardRNAppDevice()) && (
            <Box className={classes.shareWrapper} onClick={handleShare}>
              <div>
                <ShareIcon fill={companyTheme?.ctaColor} />
              </div>
              <div>
                <Typography variant="h6">More share options</Typography>
                <p>
                  (including
                  <span>
                    <NearByIcon fill={companyTheme?.ctaColor} />
                  </span>
                  Nearby Share)
                </p>
              </div>
            </Box>
          )}
        {isWindows && isNavigatorShareAvailable && (
          <Box className={classes.shareWrapper} onClick={handleShare}>
            <div>
              <ShareIcon fill={companyTheme?.ctaColor} />
            </div>
            <div>
              <Typography variant="h6" color={companyTheme?.secondaryColor}>
                More share options
              </Typography>
            </div>
          </Box>
        )}
      </Box>
    </Modal>
  );
}
