import * as React from 'react';

interface Props {
  fill?: string;
  height?: string;
  width?: string;
  onClick?: (event?: any) => void;
  className?: string;
  cursor?: string;
}

const ShareIcon: React.FC<Props> = ({
  fill = 'currentColor',
  height = 17,
  width = 22,
  onClick = () => {},
  className = '',
  cursor = 'pointer',
}) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width={width}
      height={height}
      viewBox="0 0 17 22"
      fill="none"
      cursor={cursor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" fill="transparent" />
      <path
        d="M8.5 0L4.5 4H7.5V13H9.5V4H12.5M14.5 22H2.5C1.39 22 0.5 21.1 0.5 20V8C0.5 7.46957 0.710714 6.96086 1.08579 6.58579C1.46086 6.21071 1.96957 6 2.5 6H5.5V8H2.5V20H14.5V8H11.5V6H14.5C15.0304 6 15.5391 6.21071 15.9142 6.58579C16.2893 6.96086 16.5 7.46957 16.5 8V20C16.5 20.5304 16.2893 21.0391 15.9142 21.4142C15.5391 21.7893 15.0304 22 14.5 22Z"
        fill={fill}
      />
    </svg>
  );
};

export default ShareIcon;
