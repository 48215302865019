import { baseAPICreator } from './baseAPICreator';
import { AddUserProps } from 'types/addUser';

export const addUserAPI = async (params: AddUserProps): Promise<any | null> => {
  const api = baseAPICreator({
    baseURL: '/user-add-by-company-api',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  try {
    const data = await api.post(`/add`, params);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
