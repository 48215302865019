import * as React from 'react';

interface Props {
  fill?: string;
  height?: string;
  width?: string;
}

const TextIcon: React.FC<Props> = ({
  fill = '#8544D8',
  height = '24px',
  width = '24px',
}) => {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.65 10.925C7.93333 10.925 8.17083 10.8292 8.3625 10.6375C8.55417 10.4458 8.65 10.2083 8.65 9.925C8.65 9.64167 8.55417 9.40417 8.3625 9.2125C8.17083 9.02083 7.93333 8.925 7.65 8.925C7.36667 8.925 7.12917 9.02083 6.9375 9.2125C6.74583 9.40417 6.65 9.64167 6.65 9.925C6.65 10.2083 6.74583 10.4458 6.9375 10.6375C7.12917 10.8292 7.36667 10.925 7.65 10.925ZM12.075 10.925C12.3583 10.925 12.5958 10.8292 12.7875 10.6375C12.9792 10.4458 13.075 10.2083 13.075 9.925C13.075 9.64167 12.9792 9.40417 12.7875 9.2125C12.5958 9.02083 12.3583 8.925 12.075 8.925C11.7917 8.925 11.5542 9.02083 11.3625 9.2125C11.1708 9.40417 11.075 9.64167 11.075 9.925C11.075 10.2083 11.1708 10.4458 11.3625 10.6375C11.5542 10.8292 11.7917 10.925 12.075 10.925ZM16.325 10.925C16.6083 10.925 16.8458 10.8292 17.0375 10.6375C17.2292 10.4458 17.325 10.2083 17.325 9.925C17.325 9.64167 17.2292 9.40417 17.0375 9.2125C16.8458 9.02083 16.6083 8.925 16.325 8.925C16.0417 8.925 15.8042 9.02083 15.6125 9.2125C15.4208 9.40417 15.325 9.64167 15.325 9.925C15.325 10.2083 15.4208 10.4458 15.6125 10.6375C15.8042 10.8292 16.0417 10.925 16.325 10.925ZM2 22V3.5C2 3.11667 2.15 2.77083 2.45 2.4625C2.75 2.15417 3.1 2 3.5 2H20.5C20.8833 2 21.2292 2.15417 21.5375 2.4625C21.8458 2.77083 22 3.11667 22 3.5V16.5C22 16.8833 21.8458 17.2292 21.5375 17.5375C21.2292 17.8458 20.8833 18 20.5 18H6L2 22ZM3.5 18.375L5.375 16.5H20.5V3.5H3.5V18.375Z"
        fill={fill}
      />
    </svg>
  );
};

export default TextIcon;
