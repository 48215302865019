export const muiTextField = {
  defaultProps: {
    InputLabelProps: {},
    InputProps: {
      sx: {
        height: '56px',
      },
    },
  },
};
