import { PropsWithChildren, ReactElement } from 'react';
import classNames from 'classnames';
import QRCodeLibWithoutLogo from 'react-qr-code';
import { makeStyles } from '@mui/styles';
import { renderToString } from 'react-dom/server';
import { StyledProps } from 'types/styled';
import { Theme } from '@mui/material/styles';
import QRCodeWithLogo from './QRCodeWithLogo';

const useStyles = makeStyles<Theme, { qrLogoColor?: string; qrBgColor?: string }>(
  (theme) => ({
    container: {
      display: 'inline-block',
      position: 'relative',
      background: ({ qrBgColor }: any) => qrBgColor ?? theme.palette.common.white,
      boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.15)',
      borderRadius: 16,
      padding: '16px',
    },
    logoContainer: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: 48,
      height: 48,
      '& > svg': {
        width: 48,
        height: 48,
        fill: ({ qrLogoColor }: any) => qrLogoColor,
      },
    },
  })
);

interface QRCodeProps extends StyledProps {
  code: string;
  size?: number;
  logo?: string;
  qrLogoColor?: string;
  qrCodeColor?: string;
  qrBgColor?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}

export const QRCode = ({
  code,
  size = 256,
  className,
  children,
  qrLogoColor,
  qrCodeColor,
  qrBgColor,
  onClick = () => {},
}: PropsWithChildren<QRCodeProps>): ReactElement => {
  const classes = useStyles({ qrLogoColor, qrBgColor });

  if (!children) {
    return (
      <div className={classNames(classes.container, className)} onClick={onClick}>
        <QRCodeLibWithoutLogo size={size} value={code} fgColor={qrCodeColor} />
      </div>
    );
  }

  return (
    <div className={classNames(classes.container, className)} onClick={onClick}>
      <QRCodeWithLogo
        value={code}
        logoSrc={renderToString(<>{children}</>)}
        size={248}
        fgColor={qrCodeColor as string}
      />
    </div>
  );
};
