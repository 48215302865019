import { reactNativeUserAgent } from 'constants/userAgents';

/**
 * Function to check if device is qcard react native app.
 */
export const checkQCardRNAppDevice = () =>
  !!(
    navigator &&
    navigator.userAgent &&
    navigator.userAgent.includes(reactNativeUserAgent) &&
    window?.ReactNativeWebView
  );

/**
 * Function to post Data to QCard RN app
 * @param type: string
 * @param value: object
 */
export const postMessageToQCardApp = (type: string | number, value: any) => {
  window?.ReactNativeWebView?.postMessage(
    JSON.stringify({
      type,
      value,
    })
  );
};
