import { styled } from '@mui/material';

export const PageWrapper = styled('div')({
  position: 'relative',
  minHeight: '100vh',
});

export const PageContainer = styled('div')({
  width: '1030px',
  margin: '0px auto',
  padding: '16px 15px 0',
  '@media screen and (max-width: 769px)': {
    width: '100%',
  },
});

export const Contents = styled('div')({
  width: '640px',
  margin: '150px auto',
  textAlign: 'center',
  '@media screen and (max-width: 769px)': {
    width: '100%',
    padding: '10px',
  },
});
