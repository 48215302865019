import { FormControl, TextField, styled } from '@mui/material';

// Page Wrapper
export const PageWrapper = styled('div')({
  position: 'relative',
  minHeight: '100vh',
});

export const PageWrapperBG = styled('div')({
  position: 'relative',
  minHeight: '100vh',
  background: '#F8F9FA;',
});

// Header Container
export const HeaderContainer = styled('div')({
  maxWidth: '100%',
  width: '1130px',
  paddingLeft: '15px',
  paddingRight: '15px',
  margin: '0 auto',
});

// Container MD = 1000px
export const ContainerMD = styled('div')({
  maxWidth: '100%',
  width: '1030px',
  paddingLeft: '15px',
  paddingRight: '15px',
  margin: '0 auto',
});
// Container with gap 24px - DA Downloadable Assets
export const ContainerDA = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100%',
  width: '1030px',
  paddingLeft: '15px',
  paddingRight: '15px',
  margin: '0 auto',
  gap: '24px',
});
// Top Bar Block Line with Primary Color
export const TopBarBlock = styled('div')(({ theme }) => ({
  background: theme.palette.primary.main,
  height: 40,
  position: 'absolute',
  right: 0,
  left: 0,
  top: 0,
}));

export const TextFieldInput = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'ctaColor',
})<{ ctaColor?: string }>(({ ctaColor }) => ({
  '& label': {
    color: ctaColor,
  },
  '& label.Mui-focused': {
    color: ctaColor,
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: ctaColor,
    },
    '&.Mui-focused fieldset': {
      borderColor: ctaColor,
    },
  },
  '& .MuiFormHelperText-root': {
    fontSize: '13px',
    marginLeft: '0px',
  },
}));

export const FormControlInput = styled(FormControl, {
  shouldForwardProp: (prop) => prop !== 'ctaColor',
})<{ ctaColor?: string }>(({ ctaColor }) => ({
  '& label': {
    color: ctaColor,
  },
  '& label.Mui-focused': {
    color: ctaColor,
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: ctaColor,
    },
    '&.Mui-focused fieldset': {
      borderColor: ctaColor,
    },
  },
}));
