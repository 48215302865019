import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useRef,
  useEffect,
} from 'react';

import { Layout } from 'components/Layout';
import MobileLandscape from 'pages/Home/MobileLandscape';
import { useMediaQuery } from '@mui/material';
import { getOrientation } from 'utils/device';
import { useProfile } from './useProfile';
import { getStreetAddress } from 'utils/string';
import { LoadingModal } from 'components/LoadingModal';

interface ContextType {
  isEmbedded: boolean;
  openMenu: boolean;
  setOpenMenu: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  showLoginModal: boolean;
  setShowLoginModal: Dispatch<SetStateAction<boolean>>;
}

const layoutContext = createContext<ContextType>({
  isEmbedded: false,
  openMenu: false,
  loading: false,
  showLoginModal: false,
  setOpenMenu: () => {},
  setLoading: () => {},
  setShowLoginModal: () => {},
});

export const LayoutProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNodeArray;
}) => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const isHavingMobileHeight = useMediaQuery('(max-height:500px)');
  const [isLandscapeMode, setIsLandscapeMode] = useState(
    getOrientation().includes('landscape')
  );
  const { companyEntity, profile, profileImgUrl, companyImgUrl } = useProfile();

  const isEmbedded = useRef(false);

  if (window.self !== window.top || /WebView/.test(window.navigator.userAgent)) {
    isEmbedded.current = true;
  } else {
    isEmbedded.current = false;
  }

  const updateOrientation = () => {
    const orientationIsLandscape = getOrientation().includes('landscape');
    setIsLandscapeMode(orientationIsLandscape);
    if (orientationIsLandscape) {
      setOpenMenu(false);
    }
  };

  useEffect(() => {
    updateOrientation();
    window.addEventListener('orientationchange', updateOrientation);
    window.addEventListener('resize', updateOrientation);
    return () => {
      window.removeEventListener('orientationchange', updateOrientation);
      window.removeEventListener('resize', updateOrientation);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <layoutContext.Provider
      value={{
        isEmbedded: isEmbedded.current,
        openMenu,
        loading,
        setOpenMenu,
        setLoading,
        showLoginModal,
        setShowLoginModal,
      }}
    >
      <Layout>
        {isHavingMobileHeight && isLandscapeMode ? (
          <>
            <MobileLandscape
              profileQRCode={profile?.metatag?.canonical_url}
              name={
                profile?.field_first_name +
                (profile?.field_last_name ? ' ' + profile?.field_last_name : '')
              }
              role={profile?.field_position}
              profileImgUrl={profileImgUrl}
              companyImgUrl={companyImgUrl}
              email={profile?.mail ?? ''}
              address={getStreetAddress({
                addressLine: profile?.field_address_1 ?? '',
                city: profile?.field_city ?? '',
                state: profile?.field_state ?? '',
                zipCode: profile?.field_zip ?? '',
              })}
              layout={companyEntity?.paperviewLayout ?? ''}
              backFlip={companyEntity?.backFlipLayout ?? ''}
              companyEntity={companyEntity}
            />
            <LoadingModal loading={!companyEntity} />
          </>
        ) : (
          children
        )}
      </Layout>
    </layoutContext.Provider>
  );
};

export const useLayout = () => useContext(layoutContext);
