export const typescale = (color: string) => ({
  fontFamily: "'Montserrat', sans-serif",
  allVariants: {
    color: color,
  },
  body1: {
    fontSize: 14,
    lineHeight: '24px',
  },
  h3: {
    fontSize: 30,
    lineHeight: '40px',
  },
  h4: {
    fontSize: 24,
    lineHeight: '40px',
  },
  h5: {
    fontSize: 20,
    lineHeight: '24px',
  },
});
